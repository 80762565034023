<template>
	<ul class="sidebar-nav custom-icon-sidrbar" data-coreui="navigation" data-simplebar>
		<template v-for="(menu,index) in menus">
			<template v-if="menu.Children!=undefined && menu.Children.length>0">
				<li class="nav-group" :key="index" :class="{'show': menu.IsShow}">
					<a class="nav-link nav-group-toggle" href="javascript:void(0)" @click="onShow(menu)">
						<i :class="menu.Icon" aria-hidden="true" style="width: 18px;"></i> {{menu.Name}}
					</a>
					<ul class="nav-group-items">
						<li v-for="(ret,i) in menu.Children" class="nav-item" :key="i">
							<router-link class="nav-link" :to="ret.Link">
								<i :class="ret.Icon" aria-hidden="true" style="width: 18px;"></i> {{ret.Name}}
							</router-link>
						</li>
					</ul>
				</li>
			</template>
			<template v-else>
				<li class="nav-item" :key="index">
						<router-link class="nav-link" :to="menu.Link">
							<i :class="menu.Icon" aria-hidden="true" style="width: 18px;"></i> {{menu.Name}}
						</router-link>
				</li>
			</template>
		</template>
	</ul>
</template>
<script>
export default {
	name: 'admin-sidebar',
	data() {
		return {
			menus: [
        {
          Name: 'Dashboard',
          Icon: 'fa fa-dashboard',
          Link: '/'
        },
        {
          Name: 'Billing/Subscription',
          Icon: 'fa fa-credit-card-alt',
          Link: '/billing-subscription'
        },
        {
          Name: 'Payments',
          Icon: 'fa fa-diamond',
          Link: '/payment-list'
        },
        {
          Name: 'Clients',
          Icon: 'fa fa-registered',
          Link: '/client-list'
        },
        {
          Name: 'Billing Contact',
          Icon: 'fa fa-building-o',
          Link: '/company-list'
        },
        {
          Name: 'Accounts',
          Icon: 'fa fa-user-o',
          IsShow: false,
          Children: [
            {
              Name: 'Users',
              Icon: 'fa fa-user',
              Link: '/user-list'
            },
            {
              Name: 'Group',
              Icon: 'fa fa-bookmark',
              Link: '/user-group-list'
            },
          ]
        },
        {
          Name: 'Settings',
          Icon: 'fa fa-outdent',
          IsShow: false,
          Children: [
            {
              Name: 'Setup Mail Server',
              Icon: 'fa fa-envelope-o',
              Link: '/setting/setup-mail-server'
            },
            {
              Name: 'Setup Price',
              Icon: 'fa fa-diamond',
              Link: '/setting/setup-price'
            },
            {
              Name: 'Location',
              Icon: 'fa fa-diamond',
              Link: '/setting/location'
            }
          ]
        },
        {
          Name: 'Developers',
          Icon: 'fa fa-outdent',
          IsShow: false,
          Children: [
          {
              Name: 'Configuration',
              Icon: 'fa fa-globe',
              Link: '/developer/configuration'
            },
            {
              Name: 'Menus',
              Icon: 'fa fa-bookmark',
              Link: '/developer/menu-list'
            },
            {
              Name: 'Module',
              Icon: 'fa fa-bookmark',
              Link: '/developer/module-list'
            },
            {
              Name: 'Translate',
              Icon: 'fa fa-globe',
              Link: '/developer/translate'
            },
          ]
        }
      ]
		}
	},
	methods: {
		onShow(menu){
      menu.IsShow = !menu.IsShow;
    }
	}
}
</script>