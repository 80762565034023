import Swal from 'sweetalert2';
import axios from 'axios';
import api from './api';
import moment from 'moment';
import TextareaAutosize from 'vue-textarea-autosize';
import numeral from 'numeral';

export default {
    install(Vue) {
        Vue.use(TextareaAutosize);
        Vue.prototype.$base = () =>  api.base();

		Vue.prototype.$api = function () {
			return axios.create({
				baseURL: api.base(),
				headers: { Authorization: 'Bearer ' + api.getToken() }
			});
		};
        Vue.prototype.$now = () => moment();
        Vue.prototype.$date = (value) => moment(value);
        
        Vue.prototype.$isValid = (res) => {
			return api.isValid(res);
		}
        Vue.prototype.$swal =  Swal;
        Vue.prototype.$isEmpty = (val) => {
            return (val == '' || val == null || val == undefined);
        }
        Vue.prototype.$format = (value, format) => {
            let frm = format == undefined? 'DD/MM/YYYY': format;
            if(value != '' && value != null && value != undefined){
                return moment(value).format(frm);
            }
        }
        Vue.prototype.$money = (value, format) => {
            return numeral(value).format(format==undefined?'0,0.00':format);
        }
    }
}