<template>
  <div>
    <b-modal title="CROP IMAGE" centered ref="innerform" @shown="init">
        <div>
            <vue-cropper
                ref="cropper"
                :auto-crop-area="1"
                :src="file"
                alt="Source Image"
                :aspectRatio="1/1"
                :img-style="{ width: '100%', height: '400px' }"
            >
            </vue-cropper>
        </div>
        <template slot="modal-footer">
            <b-button variant="outline-primary" size="sm" @click="onCrop"><i class="fa fa-floppy-o" aria-hidden="true"></i> CROP</b-button>
        </template>
    </b-modal>
  </div>
</template>
<script>
export default {
    name: 'modal-croper',
    props: {
        image: String
    },
    data() {
        return {
            file: '',
            crop: ''
        };
    },
    methods: {
        init() {
            this.file = this.image;
            this.$refs.cropper.replace(this.image);
        },
        onCrop() {
            this.crop = this.$refs.cropper.getCroppedCanvas().toDataURL();
            this.$emit("croped", this.crop);
        },
  },
};
</script>
<style>
    .crop-placeholder {
        width: 100%;
        height: 200px;
        background: #ccc;
    }
</style>