<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">CONFIGURATION</span>
                <div class="d-flex" style="gap: 5px;">
                    <button type="button" class="btn btn-outline-primary btn-sm no-bor" @click="onSave"><i class="fa fa-floppy-o"></i> {{ $t('save') }}</button>
                </div>
            </div>
        </b-card-header>
        <b-card-body class="px-0">
            <div class="row" v-for="ret in configs" :key="ret.Code">
                <div class="col-sm-3">
                    <div class="form-group">
                        <label class="label-control blue">{{ret.Name}}</label>
                        <div>
                            <input v-if="ret.Type==1" class="form-control form-control-sm" type="text" v-model="ret.SettingValue"/>
                            <template v-else-if="ret.Type==2">
                                <select class="form-control form-control-sm" v-model="ret.SettingValue">
                                    <option v-for="ret in accounts" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
                                </select>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table">
                <tbody>
                    <tr v-for="ret in configs" :key="ret.Code">
                        <th style="width: 150px;">{{ret.Name}}</th>
                        <td style="width: 5px;">:</td>
                        <td>{{ret.SettingValue}}</td>
                    </tr>
                </tbody>
            </table>
        </b-card-body>
    </b-card>
</template>
<script>
    export default {
        name: 'dev-config',
        data: function () {
            return {
                configs: [
                    {
                        Code: 1,
                        Name: this.$t('upload_path'),
                        SettingValue: '',
                        Type: 1
                    },
                    {
                        Code: 20000,
                        Name: 'Accounts Payable',
                        SettingValue: '',
                        Type: 2
                    },
                    {
                        Code: 11200,
                        Name: this.$t('stock_account_id'),
                        SettingValue: '',
                        Type: 2
                    },
                    {
                        Code: 14300,
                        Name: 'VAT Input',
                        SettingValue: '',
                        Type: 2
                    }
                ],
                codes: [1, 20000,11200,14300],
                accounts: []
            }
        },
        created() {
            let loading = this.$loading.show();
            this.accountList();
            this.$api().post('system/configurations', this.codes).then(res => {
                if (this.$isValid(res)) {
                    let records = res.data.Json.Configs;
                    this.configs.forEach(element => {
                        for(var i = 0;i<records.length;i++){
                            let item = records[i];
                            if(item.Code == element.Code){
                                element.SettingValue = item.SettingValue;
                                break;
                            }
                        }
                    });
                }
            }).finally(function () { loading.hide(); });
        },
        methods: {
            async accountList(){
                let res = await this.$api().post('accounting/accounts', { Limit: 0 });
                if(this.$isValid(res)){
                    this.accounts = res.data.Json.Accounts;
                }
            },
            onSave () {
                let loading = this.$loading.show();
                this.$api().post('system/configuration/save', this.configs).then(res => {
                    if (this.$isValid(res)) {
                        this.$swal.fire('Success', 'The configuration save success.', 'success');
                    }
                }).finally(function () { loading.hide(); });
            }
        }
    }
</script>