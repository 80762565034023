<template>
	<div>
		<ClientInvoiceModal :edit-id="editKey" @saved="onSaved" ref="form"></ClientInvoiceModal>
		<Invoice ref="invoice_pdf"></Invoice>
		<b-card class="card-cus">
			<b-card-header class="px-0 card-header-content" header-bg-variant="transparent">
				<div class="d-flex justify-content-between">
					<span class="card-title">BILLING/SUBSCRIPTIONS</span>
					<div>
						<b-button variant="primary" @click="onAdd" size="sm">
							<i class="fa fa-plus-circle"></i> ADD NEW
						</b-button>
					</div>
				</div>
			</b-card-header>
			<b-card-body class="px-0">
				<div class="d-flex justify-content-between">
					<div class="d-flex gap8">
						<div class="form-group">
							<label class="form-control-label">Search...</label>
							<input type="text" class="form-control form-control-sm" v-model="filter.Text" @keyup.enter="toList"/>
						</div>
						<div class="form-group">
							<label>&nbsp;</label>
							<div>
								<b-button variant="outline-secondary" size="sm" @click="toList"><i class="fa fa-search"></i> Search</b-button>
							</div>
						</div>
					</div>
				</div>
				<br>
				<b-table
					class="data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header">
					<template #table-busy><div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div></template>
					<template v-slot:cell(InvoiceDate)="row">{{$format(row.item.InvoiceDate)}}</template>
					<template v-slot:cell(action)="row">
						<div class="d-flex" style="gap: 5px;">
							<b-button size="sm" variant="outline-primary" @click="onedit(row.item)"><i class="fa fa-pencil-square"></i></b-button>
							<b-button size="sm" variant="outline-info" @click="printInvoice"><i class="fa fa-print"></i></b-button>
							<b-button size="sm" variant="outline-danger"><i class="fa fa-trash"></i></b-button>
						</div>
					</template>
				</b-table>
			</b-card-body>
			<b-card-footer>
				<b-row class="mt-2">
                    <b-col sm="12" md="6" lg="7" xl="7">
                        <b-pagination
							v-model="filter.Page"
							:total-rows="filter.Total"
							:per-page="filter.Limit"
							align="right"
							pills
							@input="toList"/>
                    </b-col>
				</b-row>
			</b-card-footer>
		</b-card>
	</div>
</template>
<script>
import ClientInvoiceModal from './Form'
import Invoice from '../PDF/invoice'
export default {
	name: 'billing-subscription-list',
	components:{
		ClientInvoiceModal,
		Invoice
	},
	data() {
		return {
			isBusy: false,
			header: [
				{
					key: 'InvoiceNum',
					label: 'Invoice num'
				},
				{
					key: 'InvoiceDate',
					label: 'Date'
				},
				{
					key: 'DueDate',
					label: 'Due Date',
					formatter: value => this.$format(value)
				},
				{
					key: 'ClientName',
					label: 'Client'
				},
				{
					key: 'CompanyName',
					label: 'Company'
				},
				{
					key: 'Total',
					label: 'Total',
					class: 'text-end',
					formatter: value => this.$money(value)
				},
				{
					key: 'Vat',
					label: 'VAT(10%)',
					class: 'text-end',
					formatter: value => this.$money(value)
				},
				{
					key: 'GrandTotal',
					label: 'Grand Amount',
					class: 'text-end',
					formatter: value => this.$money(value)
				},
				{
					key: 'Balance',
					label: 'Balance',
					class: 'text-end',
					formatter: value => this.$money(value)
				},
				{
					key: 'action',
					label: '',
					class: 'text-center ac-width90'
				}
			],
			filter: {
				Text: '',
				Limit: 25,
				Page: 1,
				Total: 0
			},
            items: [],
			editKey: ''
		}
	},
	async mounted() {
        await this.toList();
    },
	methods: {
		printInvoice(){
			this.$refs.invoice_pdf.print({},{},{});
		},
        async toList(){
			this.isBusy = true
            let res = await this.$api().post('invoice/list', this.filter);
			if(this.$isValid(res)){
				this.items = res.data.Json.Invoices;
			}
			this.isBusy = false;
        },
		onAdd(){
			this.editKey = '';
			this.$refs.form.$refs.innerform.show();
		},
		onedit(item){
			this.editKey = item.Id;
			this.$refs.form.$refs.innerform.show();
		},
		onSaved(){
			this.editKey = '';
			this.$refs.form.$refs.innerform.hide();
			this.toList();
		}
	}
}
</script>
<style>
	.ac-width90 {
		width: 90px !important;
	}
</style>