<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">LOCATION</span>
            </div>
        </b-card-header>
        <b-card-body>
            <div class="row">
                <div class="col-sm-12"></div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <b-table :fields="columns" :items="records" :busy="isBusy1" selectable hover class="no-bor"
                        select-mode="single"    
                        @row-selected="capSelect">
                        <template #table-busy>
                            <div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
                        </template>
                        <template v-slot:cell(Capital)="row">
                            {{row.item.Code + ' : '+ row.item.Type + row.item.Name }}
                        </template>
                    </b-table>
                </div>
                <div class="col-sm-3">
                    <b-table :fields="field2" :items="districts" :busy="isBusy2"
                        select-mode="single"
                        @row-selected="disSelect"
                        selectable hover class="no-bor">
                        <template #table-busy>
                            <div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
                        </template>
                        <template v-slot:cell(District)="row">
                            {{row.item.Code + ' : '+ row.item.Type + row.item.Name }}
                        </template>
                    </b-table>
                </div>
                <div class="col-sm-3">
                    <b-table :fields="field3" :items="communes" :busy="isBusy3"
                        select-mode="single"
                        @row-selected="comSelect"
                        selectable hover class="no-bor">
                        <template #table-busy>
                            <div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
                        </template>
                        <template v-slot:cell(Commune)="row">
                            {{row.item.Code + ' : '+ row.item.Type + row.item.Name }}
                        </template>
                    </b-table>
                </div>
                <div class="col-sm-3">
                    <b-table :fields="field4" :items="villages" :busy="isBusy4"
                        select-mode="single"
                        selectable hover class="no-bor">
                        <template #table-busy>
                            <div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
                        </template>
                        <template v-slot:cell(Village)="row">
                            {{row.item.Code + ' : ' + row.item.Name }}
                        </template>
                    </b-table>
                </div>
            </div>
        </b-card-body>
        <b-modal v-model="isShowModal" modal-class="modal-x" size="sm" :title="$t('capital')" no-close-on-backdrop>
            <div class="row">
                <div class="col-sm-8">
                    <div class="form-group" :class="{ 'form-group-error': $v.model.Code.$error }">
                        <div>
                            <input type="text" class="form-control form-control-sm" :placeholder="$t('code')" v-model="$v.model.Code.$model" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group" :class="{ 'form-group-error': $v.model.Name.$error }">
                <div>
                    <input type="text" class="form-control form-control-sm" :placeholder="$t('name')" v-model="$v.model.Name.$model" />
                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-outline-success btn-sm" @click="onSave"><i class="fa fa-floppy-o"></i> {{$t('save')}}</button>
            </div>
        </b-modal>
    </b-card>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
export default {
    name: 'capital-list',
    data(){
        return {
            model: {
                Code: '',
                Name: ''
            },
            isBusy1: false,
            isBusy2: false,
            isBusy3: false,
            isBusy4: false,
            records: [],
            districts: [],
            communes: [],
            villages: [],
            columns: [
                { key: 'Capital'}
            ],
            field2: [
                { key: 'District'}
            ],
            field3: [
                { key: 'Commune'}
            ],
            field4: [
                { key: 'Village'}
            ],
            isShowModal: false
        }
    },
    validations: {
        model :{
            Code: {required},
            Name: {required}
        }
    },
    created(){
        this.toList();
    },
    methods: {
        toList(){
            let loading = this.$loading.show();
            this.$api().post('location/capitals', { }).then(res => {
                if(this.$isValid(res)){
                    this.records = res.data.Json.Capitals;
                }
            }).finally(() => loading.hide());
        },
        addNew(){
            this.$v.$reset();
            this.isShowModal = true;
        },
        onSave(){
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let loading = this.$loading.show();
                this.$api().post('setting/capital-save', this.model).then(res => {
                    if(this.$isValid(res)){
                        this.isShowModal = false;
                    }
                }).finally(() => loading.hide());
            }
        },
        onEdit(line){
            this.model = JSON.parse(JSON.stringify(line));
        },
        capSelect(item){
            if(item.length > 0){
                this.districts = [];
                this.communes = [];
                this.villages = [];
                this.isBusy2 = true;
                var req = {
                    CapitalId: item[0].Id
                };
                this.$api().post('location/districts', req).then(res => {
                    if(this.$isValid(res)){
                        this.districts = res.data.Json.Districts;
                        this.isBusy2 = false;
                    }
                });
            }
        },
        disSelect(item){
            if(item.length > 0){
                this.communes = [];
                this.villages = [];
                this.isBusy3 = true;
                var req = {
                    DistrictId: item[0].Id
                };
                this.$api().post('location/communes', req).then(res => {
                    if(this.$isValid(res)){
                        this.communes = res.data.Json.Communes;
                        this.isBusy3 = false;
                    }
                });
            }
        },
        comSelect(item){
            if(item.length > 0){
                this.villages = [];
                this.isBusy4 = true;
                var req = {
                    CommuneId: item[0].Id
                };
                this.$api().post('location/villages', req).then(res => {
                    if(this.$isValid(res)){
                        this.villages = res.data.Json.Villages;
                        this.isBusy4 = false;
                    }
                });
            }
        }
    }
}
</script>