<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">TRANSLATION</span>
                <div class="d-flex" style="gap: 5px;">
                    <button type="button" class="btn btn-primary btn-sm" @click="$refs.file.click()"><i class="fa fa-cloud-upload" aria-hidden="true"></i> {{$t('import_json_file')}}</button>
                    <b-dropdown right variant="primary" text="View As Json" size="sm">
                        <b-dropdown-item href="javascript:void(0)" @click="jsonView('en')">English</b-dropdown-item>
                        <b-dropdown-item href="javascript:void(0)" @click="jsonView('kh')">Khmer</b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </b-card-header>
        <b-card-body class="px-0">
            <input type="file" ref="file" accept="application/json" style="display: none;" @change="onJsonSelect">
            <div class="d-flex justify-content-between mb-3">
                <div class="d-flex gap8">
                    <div class="form-group">
                        <input type="text" class="form-control form-control-sm" v-model="query.Text" @keyup.enter="toList"/>
                    </div>
                    <div class="form-group">
                        <select class="form-control form-control-sm" v-model="query.LangType" @change="toList">
                            <option value="">All</option>
                            <option value="kh">Khmer</option>
                            <option value="en">English</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div>
                            <b-button variant="outline-secondary" size="sm" @click="toList"><i class="fa fa-search"></i> Search</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <table class="table table-sm">
                <thead>
                    <tr>
                        <th>{{ $t('no') }}</th>
                        <th>{{ $t('code') }}</th>
                        <th>{{ $t('lang_khmer') }}</th>
                        <th>{{ $t('lang_english') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(ret, index) in records" :key="index">
                        <td>{{index+1}}</td>
                        <td><input type="text" class="form-control form-control-sm" v-model="ret.Code" readonly /></td>
                        <td><input type="text" class="form-control form-control-sm" v-model="ret.LangKh" @blur="onChange(ret)" /></td>
                        <td><input type="text" class="form-control form-control-sm" v-model="ret.LangEn" @blur="onChange(ret)" /></td>
                    </tr>
                </tbody>
            </table>
        </b-card-body>
        <b-modal v-model="isShowModal" size="lg" no-close-on-backdrop hide-footer>
            <json-viewer :value="json" :expanded="true" :copyable="true"></json-viewer>
        </b-modal>
    </b-card>
</template>
<script>
import Vue from 'vue'
import JsonViewer from 'vue-json-viewer'
Vue.use(JsonViewer)
export default {
    name: 'translate-lang',
    data(){
        return{
            langType: 'kh',
            query: {
                Text: '',
                LangType: ''
            },
            records: [],
            formHead: { headers: { 'Content-Type': 'multipart/form-data' }},
            isShowModal: false,
            isShow: false
        }
    },
    created(){
        this.toList();
    },
    methods: {
        toList(){
            var loading = this.$loading.show();
            this.$api().post('system/translate-language', this.query).then(res => {
                if(this.$isValid(res)){
                    this.records = res.data.Json.Languages;
                }
            }).finally(function() { loading.hide(); });
        },
        onJsonSelect(){
            var loading = this.$loading.show();
            var formData = new FormData();
            formData.append('File', this.$refs.file.files[0]);
            formData.append('LangType', this.langType);
            this.$api().post('system/json-import-translate-language', formData, this.formHead).then(res => {
                if (this.$isValid(res)) {
                    this.toList();
                }
            }).finally(function () {
                loading.hide();
            });
        },
        onChange(item){
            this.$api().post('system/save-translate-language', item).then(res => {
                if(this.$isValid(res)){
                    this.toList();
                }
            });
        },
        jsonView(lang){
            this.langType = lang;
            this.isShowModal = true;
        },
    },
    computed: {
        json(){
            let json = {};
            for(var i = 0; i < this.records.length; i++){
                json[this.records[i].Code] = this.langType =='kh'?this.records[i].LangKh:this.records[i].LangEn;
            }
            return json;
        }
    }
}
</script>