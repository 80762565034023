<template>
    <div class="bag">
        <div class="container" style="margin-top: 150px">
            <div class="row justify-content-center">
                <div style="width: 320px;">
                    <div class="card-group">
                        <div class="card p-4" style="box-shadow: 0 5px 10px 10px #f2f2f2">
                            <div class="card-body">
                                <div class="text-center"><h1 class="header-login">Login</h1></div>
                                <div class="text-center">
                                    <p class="text-muted">Sign in your account</p>
                                </div>
                                <div class="form-group mb-3"
                                    :class="{ 'form-group--error': $v.form.UserName.$error }">
                                    <label class="form-control-label">Username</label>
                                    <input type="text"
                                        class="form-control form-control-sm"
                                        v-model.trim="$v.form.UserName.$model"
                                        @keyup.enter="onLogin" />
                                </div>
                                <div class="form-group mb-4"
                                    :class="{ 'form-group--error': $v.form.Password.$error }">
                                    <label class="form-control-label">Password</label>
                                    <input type="password"
                                        class="form-control form-control-sm"
                                        v-model.trim="$v.form.Password.$model"
                                        @keyup.enter="onLogin" />
                                </div>
                                <div class="form-group">
                                    <button type="button"
                                        class="btn btn-success w-100 btn-sm"
                                        @click="onLogin">
                                        Login
                                    </button>
                                </div>
                                <br>
                                <p class="text-center">Copyright &copy; 2022</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import base from '../../plugins/api';
import { required } from "vuelidate/lib/validators";
export default {
    name: 'login-user',
    data() {
        return {
            form: {
                UserName: "",
                Password: "",
            },
        }
    },
    validations: {
        form: {
            UserName: {
                required
            },
            Password: {
                required
            },
        },
    },
    methods: {
        onLogin(){
            this.$v.$touch();
            if (!this.$v.$invalid) {
                var loading = this.$loading.show();
                base.api().post("account/login", this.form).then(res => {
                    if(base.isValid(res)){
                        base.setToken(res.data.Json.Token);
                        this.$store.commit('loginSuccess', res.data.Json.UserName);
                        this.$router.push('/');
                    }
                })
                .catch((err) => {
                    this.$swal.fire("Message", err.toString(), "warning");
                })
                .finally(() => {
                    loading.hide();
                });
            }
        }
    },
}
</script>