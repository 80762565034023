<template>
	<div>
		<Modal @save="onSaved" :edit-id="editId" :v-client="clients" ref="form"></Modal>
		<b-card class="card-cus">
			<b-card-header class="px-0" header-bg-variant="transparent">
				<div class="d-flex justify-content-between">
					<span class="card-title">BILLING CONTACTS</span>
					<div>
						<b-button variant="primary" size="sm" @click="onAdd">
							<i class="fa fa-plus-circle"></i> ADD NEW
						</b-button>
					</div>
				</div>
			</b-card-header>
			<b-card-body class="px-0">
				<div class="d-flex gap8">
					<div class="form-group">
						<label class="form-control-label">Search...</label>
						<input type="text" class="form-control form-control-sm" v-model="filter.Text" @keyup.enter="toList"/>
					</div>
					<div class="form-group">
						<label class="form-control-label">Client</label>
						<select class="form-control form-control-sm" v-model="filter.ClientId" @change="toList">
							<option value="">All</option>
							<option v-for="(ret,index) in clients" :key="index" :value="ret.Id">{{ret.Name}}</option>
						</select>
					</div>
					<div class="form-group">
						<label>&nbsp;</label>
						<div>
							<b-button variant="outline-secondary" size="sm" @click="toList"><i class="fa fa-search"></i> Search</b-button>
						</div>
					</div>
				</div>
				<br>
				<b-table
					class="data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header">
					<template #table-busy>
						<div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
					</template>
					<template v-slot:cell(No)="row">{{(row.index + 1)}}</template>
					<template v-slot:cell(IsEnabled)="row">
						<span v-if="row.item.IsEnabled" class="badge" style="background-color: #28a745 !important;">Yes</span>
						<span v-else class="badge" style="background-color: #dc3545 !important;">No</span>
					</template>
					<template v-slot:cell(action)="row">
						<div class="d-flex" style="gap: 3px;">
							<b-button size="sm" variant="outline-info" @click="edit(row.item)"><i class="fa fa-pencil-square"></i></b-button>
							<b-button v-if="row.item.IsEnabled" size="sm" variant="outline-warning" @click="onConfirmState(row.item.Id, false)"><i class="fa fa-lock"></i></b-button>
							<b-button v-else size="sm" variant="outline-primary" @click="onConfirmState(row.item.Id, true)"><i class="fa fa-unlock"></i></b-button>
						</div>
					</template>
					<template #cell(DateCreated)="row">{{$format(row.item.DateCreated)}}</template>
				</b-table>
			</b-card-body>
			<b-card-footer>
				<b-row class="mt-2">
                    <b-col sm="12" md="6" lg="7" xl="7">
                        <b-pagination
							v-model="filter.Page"
							:total-rows="filter.Total"
							:per-page="filter.Limit"
							align="right"
							pills
							@input="toList"/>
                    </b-col>
				</b-row>
			</b-card-footer>
		</b-card>
	</div>
</template>
<script>
import Modal from './components/modal.vue'
export default {
	name: 'company-list',
	components:{
		Modal
	},
	data() {
		return {
			isBusy: false,
			header: [
			{
					key: 'No',
					label: 'No',
				},
				{
					key: 'Name',
					label: 'Company Name'
				},
				{
					key: 'SubExpired',
					label: 'Expire Date',
					formatter: value => this.$format(value)
				},
				{
					key: 'ClientName',
					label: 'Client'
				},
				{
					key: 'Phone',
					label: 'Phone'
				},
				{
					key: 'Email',
					label: 'Email',
					sortable: true,
				},
				{
					key: 'BillTo',
					label: 'Bill Address'
				},
				{
					key: 'CreatedBy',
					label: 'Creator',
					sortable: true
				},
				{
					key: 'DateCreated',
					label: 'Create at'
				},
				{
					key: 'IsEnabled',
					label: 'Is Active'
				},
				{
					key: 'action',
					label: 'Action',
					class: 'action-size'
				}
			],
			filter: {
				ClientId: "",
				Text: '',
				Limit: 25,
				Page: 1,
				Total: 0
			},
            items: [],
			clients: [],
			editId: ''
		}
	},
	async created(){
		await this.getClients();
	},
	mounted() {
        this.toList();
    },
	methods: {
		async getClients(){
            await this.$api().post('client-list' , { IsConfirmed: true }).then(response => {
				this.clients = response.data.Json.Clients;
			});
        },
        toList(){
			this.isBusy = true
            this.$api().post('company-list', this.filter).then(response => {
				this.items = response.data.Json.Companies
			}).finally(() => {
				this.isBusy = false
			});
        },
		async onConfirmState(id, state) {
			this.$swal.fire({
				title: "Confirm Message",
				text: "Do you want " + (state? 'enable':'disable') + '?',
				type: "question",
				icon: "warning",
				confirmButtonText: (state? 'Enable':'Disable'),
				cancelButtonText: "Cancel",
				showCancelButton: true,
				reverseButtons: true,
			}).then(async(results) => {
				if (results.isConfirmed == true) {
					await this.stateSubmit(id, state);
				}
			});
		},
		async stateSubmit(id, state){
			let res = await this.$api().post("company-disable", { Id: id, IsEnabled: state });
			if(this.$isValid(res)){
				this.toList();
			}
		},
		onAdd(){
			this.editId = '';
			this.$refs.form.$refs.innermodal.show();
		},
		edit(item){
			this.editId = item.Id;
			this.$refs.form.$refs.innermodal.show();
		},
		onSaved(){
			this.$refs.form.$refs.innermodal.hide();
			this.$swal.fire("Message", 'Company save success.',  "success");
			this.toList();
		}
	}
}
</script>
