<template>
	<div class="vl-parent" ref="ldCond">
        <ModalCrop :image="rawimg" @croped="onCroped" ref="cropForm"></ModalCrop>
        <input type="file" @change="fileChanged" ref="file" style="display: none;"/>
		<b-modal no-close-on-backdrop :title="title" centered @shown="initForm" ref="innermodal">
			<b-row>
                <b-col xl="3" lg="3" md="5" sm="12">
					<div align="center">
						<div>
							<b-img @click="selectImg" class="no_img" :src="img"/>
						</div>
					</div>
				</b-col>
				<b-col xl="9" lg="9" md="7" sm="12">
                    <div class="form-group" :class="{ 'form-group--error': $v.form.UserName.$error }">
                        <label class="form-control-label">User name</label>
                        <input class="form-control form-control-sm" type="text" v-model="$v.form.UserName.$model" />
                    </div>
                    <div class="form-group mt-2" :class="{ 'form-group--error': $v.form.DisplayName.$error }">
                        <label class="form-control-label">Display Name</label>
                        <input class="form-control form-control-sm" type="text" v-model="$v.form.DisplayName.$model" />
                    </div>
                </b-col>
			</b-row>
            <b-row>
                <b-col xl="6" lg="6" md="6" sm="12">
                    <div class="form-group mt-2">
                        <label class="form-control-label">Email</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.Email" />
                    </div>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="12">
                    <div
                        class="form-group mt-2"
                    >
                        <label class="form-control-label">Phone Number</label>
                        <input class="form-control form-control-sm" type="text" v-model="form.PhoneNumber" />
                    </div>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="12" v-if="!editId">
                    <div class="form-group mt-2" :class="{ 'form-group--error': $v.form.PasswordHash.$error }">
                        <label class="form-control-label">Password</label>
                        <input class="form-control form-control-sm" type="password" v-model="$v.form.PasswordHash.$model" />
                    </div>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="12" v-if="!editId">
                    <div class="form-group mt-2" :class="{ 'form-group--error': $v.form.ConfirmPassword.$error }">
                        <label class="form-control-label">Confirm Password</label>
                        <input class="form-control form-control-sm" type="password" v-model="$v.form.ConfirmPassword.$model" />
                    </div>
                </b-col>
                <b-col xl="6" lg="6" md="6" sm="12">
                    <div class="form-group mt-2" :class="{ 'form-group--error': $v.form.GroupId.$error }">
                        <label class="form-control-label">Group Name</label>
                        <select class="form-control form-control-sm" v-model="form.GroupId">
                            <option v-for="ret in groups" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
                        </select>
                    </div>
                </b-col>
            </b-row>
			<template slot="modal-footer">
				<b-button variant="outline-primary" size="sm" @click="onSave"><i class="fa fa-floppy-o" aria-hidden="true"></i> {{ okModal }}</b-button>
			</template>
		</b-modal>
	</div>
</template>
<script>
import { required, sameAs, requiredIf } from "vuelidate/lib/validators";
import ModalCrop from './ModalCrop';
export default {
    name: 'modal-user',
    props: {
        keyId: {
            type: String,
            default: ''
        }
    },
    components:{
        ModalCrop
    },
    data() {
        return {
            okModal: "Save",
            title: "NEW USER",
            form: {
                UserName: '',
                Email: '',
                DisplayName: '',
                PasswordHash: '',
                ConfirmPassword:'',
                PhoneNumber: '',
                File: '',
                GroupId: '',
                UserTypeId: 1
            },
			dafaultForm: {},
            img: 'img/No_image_available.png',
            groups: [],
            rawimg: '',
            editId: this.keyId,
            actionLink: 'account-create',
        };
    },
    validations: {
        form: {
			UserName: { required },
            DisplayName:{ required },
            GroupId:{ required},
            PasswordHash: {
				required: requiredIf((vm) => {
                    return vm.Id == undefined || vm.Id == null || vm.Id == '';
                })
			},
            ConfirmPassword: {
                required: requiredIf((vm) => {
                    return vm.Id == undefined || vm.Id == null || vm.Id == '';
                }),
                sameAsPassword: sameAs('PasswordHash')
            }
        },
    },
    async created(){
        this.dafaultForm = JSON.parse(JSON.stringify(this.form));
        await this.getGroup();
    },
    methods: {
        async getGroup(){
			let res = await this.$api().post('user/groups',{});
            if(this.$isValid(res)){
                this.groups = res.data.Json.Groups;
			}
		},
        async initForm(){
            this.img = 'img/No_image_available.png';
            if(this.editId != null && this.editId != ''){
                await this.findById(this.editId);
            }
            this.$v.$reset();
        },
        async findById(id){
            let loading = this.$loading.show({
                container: this.$refs.ldCond
            });
            let res = await this.$api().post('account/find' , { Id: id});
            if(this.$isValid(res)){
                let profile = res.data.Json.UserInfo;
                if(profile != undefined && profile.Profile){
                    this.img = this.$base() + 'image/profile/' + profile.Profile;
                }
				this.form = res.data.Json.User;
			}
            loading.hide();
        },
        selectImg(){
            this.$refs.file.click();
        },
        fileChanged($event){
            let file = $event.target.files[0];
            let extension = file.name.split('.').pop().toLowerCase();
            if(['jpg', 'jpeg', 'png'].includes(extension)){
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.rawimg = e.target.result;
                    this.form.FileExtension = extension;
                    this.$refs.cropForm.$refs.innerform.show();
                };
                reader.readAsDataURL(file);
            }else{
                this.$swal.fire('Waring message', 'File extension allow only [jpg, jpeg, png].', 'warning');
            }
        },
        onCroped(e){
            this.img = e;
            this.form.File = e;
            this.$refs.cropForm.$refs.innerform.hide();
        },
        async onSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let loading = this.$loading.show({
                    container: this.$refs.ldCond
                });
                let res = await this.$api().post(this.actionLink, this.form);
                if(this.$isValid(res)){
                    this.editId = '';
                    this.$emit("save", this.form);
                    loading.hide();
                }
            }
        }
    },
    watch: {
        keyId(nval){
            this.editId = nval;
            if (nval == '') {
                this.title = "NEW USER";
                this.okModal = "Save";
                this.form = JSON.parse(JSON.stringify(this.dafaultForm));
                this.actionLink = 'account-create';
            } else{
                this.okModal = "Update";
                this.title = "UPDATE USER";
                this.actionLink = 'account-update';
            }
        },
    },
};
</script>
<style scoped>
    .no_img{
        width: 100px;
        height: 110px;
        border: 1px solid #c8c8be;
        border-radius: 3px;
    }
</style>