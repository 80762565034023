<template>
	<ul class="header-nav ms-3">
		<li class="nav-item dropdown">
			<a class="nav-link py-0" data-coreui-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false" @click="onShow">
					{{$store.state.UserName}} <div class="avatar avatar-md">
						<img class="avatar-img" src="user.jpg">
					</div>
			</a>
			<div class="dropdown-menu dropdown-menu-end pt-0" :class="{'show': show}" style="position: absolute; inset: 0px 0px auto auto; margin: 0px; transform: translate3d(0px, 42px, 0px);">
				<div class="dropdown-header bg-light py-2">
					<div class="fw-semibold">Account</div>
				</div>
				<a class="dropdown-item cus-user" href="#">
					<i class="fa fa-bell" aria-hidden="true"></i> Notifications
				</a>
				<a class="dropdown-item cus-user" href="#">
					<i class="fa fa-user-circle-o" aria-hidden="true"></i> Profile
				</a>
				<a class="dropdown-item cus-user" href="javascript:void(0)" @click="onChangePassword">
					<i class="fa fa-key" aria-hidden="true"></i> Change Password
				</a>
				<div class="dropdown-item cus-user" @click="logOut">
					<i class="fa fa-arrow-circle-o-left" aria-hidden="true"></i> Logout
				</div>
			</div>
		</li>
	</ul>
</template>
<script>
export default {
	data(){
		return {
			show: false
		}
	},
	methods: {
		onShow(){
			this.show = !this.show;
		},
		onChangePassword() {
			this.show = false;
			this.$store.commit('changePassword', 1);
		},
		logOut(){
			localStorage.clear();
      window.location.href = "/login";
		}
	}
}
</script>