<template>
    <div id="app">
      <router-view />
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss">
$fa-font-path: "~font-awesome/fonts/";
@import "font-awesome/scss/font-awesome.scss";
@import "assets/scss/style";
</style>