<template>
	<div>
		<b-modal
			v-model="alert_modal"
			no-close-on-backdrop
			hide-header-close
			:title="Header"
			scrollable
			centered
		>
			<div>
				<div
					class="form-group"
					:class="{ 'form-group--error': $v.form.PasswordHash.$error }"
				>
					<label class="form-control-label">Password</label>
					<input class="form-control" type="password" v-model="$v.form.PasswordHash.$model" />
					<div v-if="$v.form.PasswordHash.$dirty">
					<div class="error" v-if="!$v.form.PasswordHash.required">
						Password is required
					</div>
					</div>
				</div>
                <div
					class="form-group mt-2"
					:class="{ 'form-group--error': $v.form.ConfirmPassword.$error }"
				>
					<label class="form-control-label">Confirm Password</label>
					<input class="form-control" type="password" v-model="$v.form.ConfirmPassword.$model" />
					<div v-if="$v.form.ConfirmPassword.$dirty">
                        <div class="error" v-if="!$v.form.ConfirmPassword.sameAsPassword">
                            password and confirm password should match
                        </div>
					</div>
				</div>
			</div>
			<template slot="modal-footer">
				<b-button @click="closeModal(1)" variant="outline-danger">
				<i class="fa fa-trash-o" aria-hidden="true"></i>
				Cancel</b-button
				>
				<b-button @click="save" variant="outline-primary">
					<i class="fa fa-floppy-o" aria-hidden="true"></i>
					{{ okModal }}</b-button
				>
			</template>
		</b-modal>
	</div>
</template>
<script>
import { required, sameAs } from "vuelidate/lib/validators";
export default {
    name: 'modal-change-password',
    props: {
        myModal: {
            type: Number,
            default: 0,
        },
        rowItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            okModal: "",
            Header: "CHANGE PASSWORD",
            alert_modal: false,
            form: {
                PasswordHash: "",
                ConfirmPassword: ""
            },
			dafaultForm: {
                PasswordHash: "",
                ConfirmPassword: ""
            },
        };
    },
    validations: {
        form: {
			PasswordHash: {required},
            ConfirmPassword: { required, sameAsPassword: sameAs('PasswordHash') }
        },
    },
    watch: {
        myModal: function (value) {
            if(value == 1){
                this.okModal = "Save";
                this.alert_modal = true;
            }
        },
    },
    methods: {
        save() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.$api().post("account/change-password", this.form)
                .then((res) => {
                    if(this.$isValid(res)){
                       this.closeModal();                 
                    }
                }).catch((err) => {
                  this.$swal().fire({
                    title: "The Data Incorrect",
                    text: err,
                    icon: "warning",
                    confirmButtonText: "Try Again",
                  });
                });
            }
        },
    closeModal(event) {
		this.form = Object.assign({}, this.dafaultForm);
		this.$v.$reset();
		this.$emit("closeModal", event);
		this.alert_modal = false;
    },
  },
};
</script>