<template>
    <b-modal no-close-on-backdrop :title="title" centered size="lg" ref="innerform" @shown="onInit" @hidden="onClosed">
        <div class="vl-parent">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <div class="form-group" :class="{ 'form-group--error': $v.model.ClientId.$error }">
                        <label class="form-control-label">Client</label>
                        <select class="form-control form-control-sm" v-model="$v.model.ClientId.$model" :disabled="isLocked" @change="onClientChanged">
                            <option v-for="ret in clients" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group" :class="{ 'form-group--error': $v.model.Num.$error }">
                        <label class="form-control-label">Receipt no</label>
                        <input type="text" class="form-control form-control-sm" v-model="$v.model.Num.$model" :disabled="isLocked"/>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="form-control-label">Receipt date</label>
                        <input type="date" class="form-control form-control-sm" v-model="model.Date"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="form-control-label">Payment method</label>
                        <input type="text" class="form-control form-control-sm" v-model="model.PaymentMethod"/>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="form-group">
                        <label class="form-control-label">Deposit to account</label>
                        <input type="text" class="form-control form-control-sm" v-model="model.Deposit"/>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label class="form-control-label">Amount in word</label>
                        <input type="text" class="form-control form-control-sm" v-model="model.TextAmount" readonly/>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12">
                    <table class="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 30px;"><input type="checkbox" v-model="isCheckAll" @click="onClickAll" :disabled="isLocked"/></th>
                                <th>Description</th>
                                <th class="text-end" style="width: 120px;">Original Amount</th>
                                <th class="text-end" style="width: 120px;">Open Balance</th>
                                <th class="text-end" style="width: 120px;">Payment</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="lines.length==0">
                                <td colspan="6" class="text-center">There are no records to show</td>
                            </tr>
                            <template v-else>
                                <tr v-for="(ret,index) in lines" :key="index">
                                    <td class="text-center"><input type="checkbox" v-model="ret.Checked" @click="onChecked(ret)" :disabled="isLocked"/></td>
                                    <td>{{ret.InvoiceNum}} ({{$format(ret.DueDate)}})</td>
                                    <td class="text-end">{{$money(ret.OrginalAmount)}}</td>
                                    <td class="text-end">{{$money(ret.Balance)}}</td>
                                    <td class="p-0">
                                        <input type="text" class="form-control form-control-sm text-end" style="border: none; border-radius: 0;"
                                            v-model="ret.Amount"
                                            :readonly="!ret.Checked"
                                            @blur="onBlur(ret)"/>
                                    </td>
                                </tr>
                            </template>
                        </tbody>
                        <tfoot>
                            <tr>
                                <th class="text-end" colspan="4">Total Amount</th>
                                <th class="text-end">{{$money(model.Amount)}}</th>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-8">
                    <div class="form-group">
                        <label class="form-control-label">Description</label>
                        <textarea type="text" class="form-control form-control-sm" v-model="model.Description"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <template slot="modal-footer">
            <b-button variant="outline-primary" size="sm" @click="onSave"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</b-button>
        </template>
    </b-modal>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { ToWords } from 'to-words';
export default {
    name: 'payment-form',
    props: {
        editId: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            title: 'NEW PAYMENT',
            model: {
                ClientId: '',
                Date: this.$now().format('YYYY-MM-DD'),
                Num: '',
                TextAmount: '',
                Amount: 0
            },
            lines: [],
            backModel: {},
            clients: [],
            isLocked: false,
            isCheckAll: false
        }
    },
    validations: {
        model: {
            Num: { required },
            ClientId: { required }
        }
    },
    async created(){
        this.backModel = JSON.parse(JSON.stringify(this.model));
        await this.getClient();
    },
    methods: {
        async onInit(){
            if(this.editId != ''){
                let loading = this.$loading.show({
					container: this.$refs.ldCond
				});
                let res = await this.$api().post('payment/find', { Id: this.editId });
				if(this.$isValid(res)){
					this.model = res.data.Json.Header;
                    this.lines = res.data.Json.Lines;
                    this.model.Date = this.$format(this.model.Date, 'YYYY-MM-DD');
				}
				loading.hide();
                this.isLocked = true;
                this.isCheckAll = true;
            }
        },
        async getClient(){
			let res = await this.$api().post('client-list', { IsConfirmed: true });
			if(this.$isValid(res)){
				this.clients = res.data.Json.Clients;
			}
		},
        async onClientChanged(){
            let loading = this.$loading.show({
                container: this.$refs.ldCond
            });
            let req = {
                ClientId: this.model.ClientId
            };
            this.lines = [];
            let res = await this.$api().post('invoice/list', req);
			if(this.$isValid(res)){
				res.data.Json.Invoices.forEach(el => {
                    this.lines.push({
                        Checked: false,
                        ClientInvoiceId: el.Id,
                        InvoiceNum: el.InvoiceNum,
                        DueDate: el.DueDate,
                        OrginalAmount: el.GrandTotal,
                        Balance: el.Balance,
                        Amount: 0
                    });
                });
                loading.hide();
			}
        },
        async onSave(){
            this.$v.$touch();
			if (!this.$v.$invalid) {
                if(this.model.Total <= 0){
                    this.$swal.fire('Warning message', 'Total amount should be bigger than 0.', 'warning');
                    return;
                }
				let loading = this.$loading.show({
					container: this.$refs.ldCond
				});
				let req = {
					Header: this.model,
					Lines: this.lines
				};
				let res = await this.$api().post('payment/save', req);
				if(this.$isValid(res)){
					this.$emit('saved', req);
				}
				loading.hide();
			}
        },
        onClosed(){
            this.lines = [];
            this.model = JSON.parse(JSON.stringify(this.backModel));
            this.$v.$reset();
        },
        onClickAll(e){
            this.lines.forEach(el => {
                el.Checked = e.target.checked;
                el.Amount = el.Checked?el.Balance: 0;
            });
            this.calculate();
        },
        onChecked(line){
            line.Amount = line.Checked?line.Balance: 0;
            this.calculate();
        },
        onBlur(line){
            if(Number(line.Amount) > line.Balance){
                line.Amount = line.Balance;
                this.$swal.fire('Message', 'Input amount payment can not bigger than balance', 'info');
            }
            this.calculate();
        },
        calculate(){
            let total = 0;
            this.lines.forEach(el => {
                total += Number(el.Amount);
            });
            this.model.Amount = total;
            this.toTextAmount();
        },
        toTextAmount(){
            const toWords = new ToWords({localeCode: 'en-US'});
            this.model.TextAmount = toWords.convert(this.model.Amount, { currency: true }).replace('Dollars','Riels');
        }
    }
}
</script>