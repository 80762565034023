<template>
    <div>
			<ChangePassword :myModal="$store.state.isChangePassword" @closeModal="closeModalChangePassword"></ChangePassword>
      <div class="sidebar sidebar-dark sidebar-fixed" :class="{'hide show':hide}" id="sidebar">
        <div class="sidebar-brand d-md-flex">{{$store.state.UserName}}</div>
        <Sidebar></Sidebar>
      </div>
      <div class="wrapper d-flex flex-column min-vh-100 bg-light">
        <header class="header header-sticky mb-4">
        <div class="container-fluid">
          <button class="header-toggler px-md-0 me-md-3" type="button" @click="onHide">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </button>
          <ul class="header-nav d-none d-md-flex">
            <li class="nav-item"><a class="nav-link" href="#">Dashboard</a></li>
          </ul>
          <ul class="header-nav ms-auto"></ul>
          <RightMenu></RightMenu>
        </div>
      </header>
      <div class="body flex-grow-1 px-3">
        <div class="container-flud">
            <router-view></router-view>
        </div> 
      </div>
    </div>
  </div>
</template>
<script>
import ChangePassword from './components/ChangePassword';
import Sidebar from './components/Sidebar';
import RightMenu from './components/RightMenu.vue';
export default {
	components:{
		ChangePassword,
		Sidebar,
    RightMenu
	},
  data() {
    return {
      hide: false,
      show: false,
      myModal: 0
    }
  },
  methods: {
		closeModalChangePassword(){
      this.$store.commit('changePassword', 0);
		},
    onHide(){    
        this.hide = !this.hide;
    }
  }
}
</script>