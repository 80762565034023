<template>
	<b-modal no-close-on-backdrop :title="title" ref="innermodal" @shown="initForm" @hidden="formClosed">
		<div class="vl-parent" ref="ldCond">
            <div class="row mb-2">
                <div class="form-group" :class="{ 'form-group--error': $v.form.ClientId.$error }">
                    <label class="form-control-label">Client name</label>
                    <select class="form-control form-control-sm" v-model="$v.form.ClientId.$model">
                        <option v-for="(ret,index) in clients" :key="index" :value="ret.Id">{{ret.Name}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-2">
                <div class="form-group" :class="{ 'form-group--error': $v.form.Name.$error }">
                    <label class="form-control-label">Company name</label>
                    <input type="text" class="form-control form-control-sm" v-model="$v.form.Name.$model" />
                </div>
            </div>
            <div class="row mb-2">
                <div class="form-group">
                    <label class="form-control-label">VAT TIN</label>
                    <div class="row">
                        <div class="col-sm-4"><input type="text" class="form-control form-control-sm" v-model="form.TinHead" /></div>
                        <div class="col-sm-8"><input type="text" class="form-control form-control-sm" v-model="form.VatTin" /></div>
                    </div>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-4 form-group" :class="{ 'form-group--error': $v.form.Phone.$error }">
                    <label class="form-control-label">Phone</label>
                    <input type="text" class="form-control form-control-sm" v-model="$v.form.Phone.$model" />
                </div>
                <div class="col-md-8 form-group" :class="{ 'form-group--error': $v.form.Email.$error }">
                    <label class="form-control-label">Email</label>
                    <input type="text" class="form-control form-control-sm" v-model="$v.form.Email.$model" />
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12 form-group">
                    <label class="form-control-label">Address</label>
                    <input type="text" class="form-control form-control-sm" v-model="form.Address" />
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-md-12 form-group">
                    <label class="form-control-label">Bill Address</label>
                    <input type="text" class="form-control form-control-sm" v-model="form.BillTo" />
                </div>
            </div>
        </div>
        <template slot="modal-footer">
            <b-button @click="onSave" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> {{ okModal }}</b-button>
        </template>
    </b-modal>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
    name: 'modal-company',
    props: {
        editId: {
            type: String,
            default: ''
        },
        vClient: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            okModal: "Save",
            title: "NEW COMPANY",
            form: {
                Name: '',
                ClientId: '',
                Phone: '',
                Email: '',
            },
            backForm: {},
            clients: this.vClient
        };
    },
    validations: {
        form: {
			ClientId: { required },
			Name: { required },
			Email: { required },
			Phone: { required },
        }
    },
    watch: {
        vClient(items){
            this.clients = items;
        }
    },
    created(){
        this.backForm = JSON.parse(JSON.stringify(this.form));
    },
    methods: {
        async initForm(){
            if(this.editId != ''){
                let loading = this.$loading.show({
                    container: this.$refs.ldCond
                });
                let res = await this.$api().post('company-find', { Id: this.editId });
                if(this.$isValid(res)){
                    this.form = res.data.Json.Company;
                    loading.hide();
                }
            }
        },
        async onSave() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let loading = this.$loading.show({
                    container: this.$refs.ldCond
                });
                let res = await this.$api().post("company-save", this.form);
                if(this.$isValid(res)){
                    this.$emit("save", this.form);
                }
                loading.hide();
            }
        },
        formClosed(){
            this.form = JSON.parse(JSON.stringify(this.backForm));
            this.$v.$reset();
        }
  },
};
</script>