<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">SETUP SUBSCRIPTION PRICE</span>
            </div>
        </b-card-header>
        <b-card-body>
            <b-table
					class="table-sm data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header">
					<template #table-busy>
						<div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
					</template>
                    <template v-slot:cell(action)="row">
						<b-button size="sm" variant="outline-primary" @click="onedit(row.item)"><i class="fa fa-pencil-square"></i></b-button>
					</template>
				</b-table>
        </b-card-body>
        <b-modal no-close-on-backdrop :title="actionTitle" ref="form">
            <div class="vl-parent" ref="ldCond">
                <div class="row mb-3">
                    <div class="col-sm-2">
                        <div class="form-group">
                            <label>Code</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.Code" readonly/>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="form-group">
                            <label>Name</label>
                            <input type="text" class="form-control form-control-sm" v-model="form.Name"/>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-6 form-group">
                        <label>Default Free</label>
                        <input type="number" class="form-control form-control-sm" v-model="form.DefaultFree"/>
                    </div>
                    <div class="col-sm-6 form-group">
                        <label>Unit Price</label>
                        <input type="number" class="form-control form-control-sm" v-model="form.UnitPrice"/>
                    </div>
                </div>
            </div>
            <template slot="modal-footer">
                <b-button @click="onSave" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</b-button>
            </template>
        </b-modal>
    </b-card>
</template>
<script>
export default {
    name: 'setup_price',
    data(){
        return {
            filter: {},
            items: [],
            header: [
                {
                    key: 'Code',
                    label: 'Code'
                },
                {
                    key: 'Name',
                    label: 'Description'
                },
                {
                    key: 'DefaultFree',
                    label: 'Default Free',
                    class: 'text-center'
                },
                {
                    key: 'UnitPrice',
                    label: 'Unit Price',
                    class: 'text-end',
                    formatter: value => this.$money(value)
                },
                {
                    key: 'Action',
                    label: '',
                    class: 'text-end'
                }
            ],
            actionTitle: 'SETUP PRICE',
            form: {},
            isBusy: false
        }
    },
    async created(){
        await this.toList();
    },
    methods: {
        onedit(item){
            this.form = JSON.parse(JSON.stringify(item));
            this.$refs.form.show();
        },
        async toList(){
            this.isBusy = true;
            let res = await this.$api().post('list_setup_price');
            if(this.$isValid(res)){
                this.items = res.data.Json.Subscribes;
            }
            this.isBusy = false;
        },
        async onSave(){
            let loading = this.$loading.show({ container: this.$refs.ldCond });
            let res = await this.$api().post('save_setup_price', this.form);
            if(this.$isValid(res)){
                loading.hide();
                this.$refs.form.hide();
                this.toList();
            }
        }
    }
}
</script>