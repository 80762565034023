<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">SETUP MAIL SERVER</span>
                <div>
                    <b-button variant="primary" size="sm" @click="onAdd">
                        <i class="fa fa-plus-circle"></i> ADD NEW
                    </b-button>
                </div>
            </div>
        </b-card-header>
        <b-card-body>
            <b-table
					class="data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header">
					<template #table-busy>
						<div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
					</template>
					<template v-slot:cell(action)="row">
                        <b-button size="sm" variant="outline-info" @click="edit(row.item)"><i class="fa fa-pencil-square"></i></b-button>
					</template>
				</b-table>
        </b-card-body>
        <b-modal no-close-on-backdrop :title="actionTitle" ref="form">
            <div>
                <div class="row mb-2">
                    <div class="form-group" :class="{ 'form-group--error': $v.form.Subject.$error }">
                        <label class="form-control-label">Subject</label>
                        <input type="text" class="form-control form-control-sm" v-model="$v.form.Subject.$model" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-6 form-group" :class="{ 'form-group--error': $v.form.Email.$error }">
                        <label class="form-control-label">Email</label>
                        <input type="text" class="form-control form-control-sm" v-model="$v.form.Email.$model" />
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="form-control-label">Password</label>
                        <input type="text" class="form-control form-control-sm" v-model="form.Password" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md -8 form-group" :class="{ 'form-group--error': $v.form.Smtp.$error }">
                        <label class="form-control-label">Smpt</label>
                        <input type="text" class="form-control form-control-sm" v-model="$v.form.Smtp.$model" />
                    </div>
                    <div class="col-md-4 form-group" :class="{ 'form-group--error': $v.form.Port.$error }">
                        <label class="form-control-label">Port</label>
                        <input type="text" class="form-control form-control-sm" v-model="$v.form.Port.$model" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-md -8 form-group" :class="{ 'form-group--error': $v.form.RedirectLink.$error }">
                        <label class="form-control-label">Redirect link</label>
                        <input type="text" class="form-control form-control-sm" v-model="$v.form.RedirectLink.$model" />
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="form-control-label">Code</label>
                        <input type="text" class="form-control form-control-sm" v-model="form.Code" />
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="form-group">
                        <label class="form-control-label">Body</label>
                        <textarea-autosize class="form-control form-control-sm" v-model="form.EmailBody" :min-height="300"/>
                    </div>
                </div>
            </div>
            <template slot="modal-footer">
                <b-button @click="onSave" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</b-button>
            </template>
        </b-modal>
    </b-card>
</template>
<script>
    import { required } from "vuelidate/lib/validators";
    export default {
        name: 'mail-server',
        data(){
            return {
                filter: {},
                items: [],
                header: [
                    {
                        key: 'action',
                        label: 'Action',
                        class: 'action-size'
                    },
                    {
                        key: 'Code',
                        label: 'Code'
                    },
                    {
                        key: 'Email',
                        label: 'Email'
                    },
                    {
                        key: 'Subject',
                        label: 'Subject'
                    },
                    {
                        key: 'Smtp',
                        label: 'Smtp'
                    },
                    {
                        key: 'Port',
                        label: 'Port'
                    },
                    {
                        key: 'RedirectLink',
                        label: 'Redirect link'
                    },
                ],
                actionTitle: 'NEW MAIL SETUP',
                form: {
                    Port: '',
                    Subject: '',
                    Email: '',
                    Smtp: '',
                    RedirectLink: ''
                },
                backupForm: {},
                isBusy: false
            }
        },
        validations: {
            form: {
                Port: { required },
                Subject: { required },
                Email: { required },
                Smtp: { required },
                RedirectLink: { required }
            }
        },
        async created(){
            this.backupForm = JSON.parse(JSON.stringify(this.form));
            await this.toList();
        },
        methods: {
            async toList(){
                this.isBusy = true
                let res = await this.$api().post('list-setup-mail-server');
                if(this.$isValid(res)){
                    this.items = res.data.Json.Servers;
                    this.isBusy = false
                }
            },
            onAdd(){
                this.form = JSON.parse(JSON.stringify(this.backupForm));
                this.$v.$reset();
                this.$refs.form.show();
            },
            edit(item){
                this.form = JSON.parse(JSON.stringify(item));
                this.form.Password = '';
                this.$refs.form.show();
            },
            async onSave() {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    let loading = this.$loading.show({
                        container: this.$refs.ldCond
                    });
                    let res = await this.$api().post("setup-mail-server", this.form);
                    if(this.$isValid(res)){
                        this.$refs.form.hide();
                        this.$swal.fire("Message", 'Mail server save success.',  "success");
                        this.toList();
                    }
                    loading.hide();
                }
            }
        }
    }
</script>
