<template>
	<div>
		<ModalForm :key-id="editId" @save="onSave" ref="form"></ModalForm>
		<b-card class="card-cus">
			<b-card-header class="px-0" header-bg-variant="transparent">
				<div class="d-flex justify-content-between">
					<span class="card-title">ACCOUNTS</span>
					<div>
						<b-button variant="primary" size="sm" @click="onAdd">
							<i class="fa fa-plus-circle"></i> ADD NEW
						</b-button>
					</div>
				</div>
			</b-card-header>
			<b-card-body class="px-0">
				<div class="d-flex gap8">
					<div class="form-group">
						<label class="form-control-label">Search...</label>
						<input type="text" class="form-control form-control-sm" v-model="filter.Text" @keyup.enter="get"/>
					</div>
					<div class="form-group">
						<label class="form-control-label">User Type</label>
						<select class="form-control form-control-sm" v-model="filter.UserTypeId" @change="get">
							<option value="0">All</option>
							<option value="1">Administrator</option>
							<option value="2">Client</option>
							<option value="3">User</option>
						</select>
					</div>
					<div class="form-group">
						<label class="form-control-label">Client</label>
						<select class="form-control form-control-sm" v-model="filter.ClientId" @change="get">
							<option value="">All</option>
							<option v-for="(ret,index) in clients" :key="index" :value="ret.Id">{{ret.Name}}</option>
						</select>
					</div>
					<div class="form-group">
						<label>&nbsp;</label>
						<div>
							<b-button variant="outline-secondary" size="sm" @click="get"><i class="fa fa-search"></i> Search</b-button>
						</div>
					</div>
				</div>
				<br>
				<b-table
					class="data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header">
					<template #table-busy>
						<div class="text-center my-2">
							<b-spinner variant="info" class="align-middle"></b-spinner>
						</div>
					</template>
					<template v-slot:cell(action)="row">
						<b-dropdown text="Action" variant="outline-secondary" class="drop-sm" size="sm">
							<template v-if="row.item.UserTypeId==1">
								<b-dropdown-item-button @click="edit(row.item)"> <i class="fa fa-pencil-square"></i> Edit</b-dropdown-item-button>
							</template>
							<b-dropdown-item-button @click="generatePassord(row.item)">
								<i class="fa fa-random"></i> Reset New Passord
							</b-dropdown-item-button>
						</b-dropdown>
					</template>
				</b-table>
			</b-card-body>
			<b-card-footer>
				<b-row class="mt-2">
                    <b-col sm="12" md="6" lg="7" xl="7">
                        <b-pagination
							v-model="filter.Page"
							:total-rows="filter.Total"
							:per-page="filter.Limit"
							align="right"
							pills
							@input="get"/>
                    </b-col>
				</b-row>
			</b-card-footer>
		</b-card>
	</div>
</template>
<script>
import ModalForm from './components/ModalUserForm'
export default {
	name: 'user-list',
	components:{
		ModalForm
	},
	data() {
		return {
			isBusy: false,
			header: [
				{
					key: 'action',
					label: 'Action',
					class: 'action-size'
				},
				{
					key: 'UserName',
					label: 'Username',
					sortable: true
				},
				{
					key: 'UserTypeName',
					label: 'User type',
				},
				{
					key: 'Email',
					label: 'Email'
				},
				{
					key: 'ClientName',
					label: 'Client'
				}
			],
			form: {
				UserName: '',
                Email: '',
                Verify: '',
                PhoneNumber: '',
                DisplayName: '',
                GroupId: '',
				Id: "",
				PasswordHash: ""
			},
			filter: {
				Text: '',
				Limit: 20,
				Page: 1,
                Total: 0,
				ClientId: '',
				UserTypeId: 0
			},
			editId: '',
            items: [],
			clients: []
		}
	},
	async created(){
		await this.getClients();
	},
	mounted() {
        this.get();
    },
	methods: {
		async getClients(){
            await this.$api().post('client-list' , {}).then(response => {
				this.clients = response.data.Json.Clients;
			});
        },
        get(){
			this.isBusy = true
            this.$api().post('account-list', this.filter).then(res => {
				if(this.$isValid(res)){
					this.items = res.data.Json.Users;
					this.filter.Total = res.data.Total;
				}
			}).finally(() => {
				this.isBusy = false
			});
        },
		generatePassord(item) {
			this.form= Object.assign({}, item);
			this.form.PasswordHash = this.form.UserName + "@123456";
			this.$swal.fire({
				title: "Reset New Password",
				text: "Do you want reset it?",
				type: "question",
				icon: "warning",
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				reverseButtons: true,
			}).then((results) => {
				if (results.isConfirmed == true) {
				this.$api().post("account/reset-password", this.form)
					.then((res) => {
						if(this.$isValid(res)){
							this.$swal.fire('Message', 'Generate Passord Successfully', 'success');
						}
					});
				}
			});
		},
		onAdd(){
			this.editId = '';
			this.$refs.form.$refs.innermodal.show();
		},
		edit(item){
			this.event = 1;
			this.editId = item.Id;
			this.$refs.form.$refs.innermodal.show();
		},
        clearSearch(){
			this.filter = {};
            this.get();
        },
		onSave() {
			this.$refs.form.$refs.innermodal.hide();
			this.get();
		},
	}
}
</script>
