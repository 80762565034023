<template>
	<div>
		<b-card class="card-cus">
			<b-card-header class="px-0 card-header-content" header-bg-variant="transparent">
				<div class="d-flex justify-content-between">
					<span class="card-title">USER GROUPS</span>
					<div>
						<b-button variant="primary" @click="onAdd" size="sm">
							<i class="fa fa-plus-circle"></i> ADD NEW
						</b-button>
					</div>
				</div>
			</b-card-header>
			<b-card-body class="px-0">
				<b-table
					class="data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header"
				>
					<template #table-busy>
						<div class="text-center my-2">
							<b-spinner variant="info" class="align-middle"></b-spinner>
						</div>
					</template>
					<template v-slot:cell(action)="row">
						<b-dropdown text="Action" variant="outline-secondary" class="drop-sm" size="sm">
							<b-dropdown-item-button @click="edit(row.item)"> 
								<i class="fa fa-pencil-square"></i>
								Edit
							</b-dropdown-item-button>
						</b-dropdown>
					</template>
				</b-table>
			</b-card-body>
			<b-modal v-model="isShow" no-close-on-backdrop size="sm" :title="title" centered>
				<div class="vl-parent" ref="ldCond">
					<div class="form-group" :class="{ 'form-group--error': $v.form.Name.$error }">
						<label class="form-control-label">Name</label>
						<input type="text" class="form-control form-control-sm" v-model="$v.form.Name.$model" />
					</div>
					<div class="form-group">
						<label class="form-control-label">Sort</label>
						<input type="number" class="form-control form-control-sm" v-model="form.Sort" />
					</div>
				</div>
				<template slot="modal-footer">
					<b-button @click="onSave" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</b-button>
				</template>
			</b-modal>
		</b-card>
	</div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
	name: 'user-group-list',
	data() {
		return {
			isBusy: false,
			header: [
				{
					key: 'action',
					label: 'Action',
					class: 'action-size'
				},
				{
					key: 'Name',
					label: 'Name'
				},
				{
					key: 'Sort',
					label: 'Sort'
				},
			],
			filter: {
				Text: ''
			},
            items: [],
			isShow: false,
			title: 'NEW USER GROUP',
			form: {
				Name:'',
				Sort: 1
			}
		}
	},
	validations: {
        form: {
			Name: { required }
        },
    },
	mounted() {
        this.get();
    },
	methods: {
        get(){
			this.isBusy = true;
            this.$api().post('user/groups', this.filter).then(res => {
                this.items = res.data.Json.Groups
			}).finally(() => {
				this.isBusy = false
			});
        },
		onAdd(){
			this.isShow = true;
			this.title = 'NEW USER GROUP';
		},
		edit(item){
			this.form = JSON.parse(JSON.stringify(item));
			this.isShow = true;
			this.title = "UPDATE USER GROUP";
		},
		async onSave(){
			this.$v.$touch();
            if (!this.$v.$invalid) {
				let loading = this.$loading.show({
                    container: this.$refs.ldCond
                });
                let res = await this.$api().post("user/group-save", this.form);
                if(this.$isValid(res)){
					this.isShow = false;
				}
				loading.hide();
            }
		}
	}
}
</script>
