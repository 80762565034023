<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">MODULES</span>
                <div class="d-flex" style="gap: 5px;">
                    <b-button variant="primary" @click="onAdd" size="sm">
                        <i class="fa fa-plus-circle"></i> ADD NEW
                    </b-button>
                    <button type="button" class="btn btn-primary btn-sm" @click="$refs.file.click()"><i class="fa fa-upload"></i> {{ $t('import') }}</button>
                </div>
            </div>
        </b-card-header>
        <b-card-body class="px-0">
            <input type="file" ref="file" accept="application/json" style="display: none;" @change="onJsonSelect">
            <b-table class="data-table" show-empty hover
					:busy="isLoading"
					:items="records"
					:fields="columns">
                <template #table-busy>
                    <div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
                </template>
                <template v-slot:cell(Enabled)="row">
                    <input type="checkbox" v-model="row.item.Enabled" disabled/>
                </template>
                <template v-slot:cell(AllowedClient)="row">
                    <input type="checkbox" v-model="row.item.AllowedClient" disabled/>
                </template>
                <template v-slot:cell(AllowedUser)="row">
                    <input type="checkbox" v-model="row.item.AllowedUser" disabled/>
                </template>
                <template v-slot:cell(Icon)="row">
                    <i :class="row.item.Icon" aria-hidden="true"></i>
                </template>
                <template v-slot:cell(Action)="row">
                    <b-button size="sm" variant="outline-info" @click="onedit(row.item)"><i class="fa fa-pencil-square"></i></b-button>
                </template>
            </b-table>
        </b-card-body>
        <b-modal no-close-on-backdrop centered title="MODULE" ref="form" @hidden="onFormClosed">
            <div class="vl-parent" ref="ldCond">
                <div class="row mb-3">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('name')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Name" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('url')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Url" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('lang_key')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.LangKey" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('barcode')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Code" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('icon')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Icon" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('sort')}}</label>
                            <div>
                                <input type="number" class="form-control form-control-sm" v-model="model.Sort" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group mb-2">
                            <label><input type="checkbox" v-model="model.Enabled" /> {{$t('enabled')}}</label>
                        </div>
                        <div class="form-group mb-2">
                            <label><input type="checkbox" v-model="model.AllowedClient" /> Allow client access</label>
                        </div>
                        <div class="form-group mb-2">
                            <label><input type="checkbox" v-model="model.AllowedUser" /> Allow user access</label>
                        </div>
                    </div>
                </div>
            </div>
            <template slot="modal-footer">
                <b-button @click="onSave" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</b-button>
            </template>
        </b-modal>
    </b-card>
</template>
<script>
export default {
    name: 'module-list',
    data(){
        return{
            columns: [
                { key: 'Code' },
                { key: 'Name' },
                { key: 'Icon' },
                { key: 'Sort' },
                { key: 'Url' },
                { key: 'LangKey', formatter: value => this.$t(value) },
                { key: 'Enabled' },
                { key: 'AllowedClient', label: 'Client Access' },
                { key: 'AllowedUser', label: 'User Access' },
                { key: 'Action', class: 'text-end' },
            ],
            records: [],
            modules: [],
            model: {
                Name: '',
                Code: '',
                Icon: '',
                Sort: 0,
                Enabled: true
            },
            backModel: {},
            isLoading: false,
            actionLink: 'system/module-save'
        }
    },
    created(){
        this.backModel = JSON.parse(JSON.stringify(this.model));
        this.toList();
    },
    methods: {
        toList(){
            this.isLoading = true;
            this.$api().post('system/modules', {}).then(res => {
                if(this.$isValid(res)){
                    this.records = res.data.Json.Modules;
                }
            }).finally(() => { this.isLoading = false;});
        },
        onJsonSelect(){
            var loading = this.$loading.show();
            var formData = new FormData();
            formData.append('file', this.$refs.file.files[0]);
            this.$api().post('system/json-import-module', formData, this.formHead).then(res => {
                if (this.$isValid(res)) {
                    this.toList();
                }
            }).finally(function () {
                loading.hide();
            });
        },
        onAdd(){
            this.$refs.form.show();
        },
        onedit(item){
            this.$refs.form.show();
            this.findById(item.Id);
        },
        onSave(){
            var loading = this.$loading.show({
                container: this.$refs.ldCond
            });
            this.$api().post(this.actionLink, this.model).then(res => {
                if(this.$isValid(res)){
                    this.$refs.form.hide();
                }
            }).finally(function() { loading.hide(); });
        },
        findById(id){
            var loading = this.$loading.show({
                container: this.$refs.ldCond
            });
            this.$api().post('system/module-find', { Id: id }).then(res => {
                if(this.$isValid(res)){
                    this.model = res.data.Json.Module;
                }
            }).finally(function() { loading.hide(); });
        },
        onFormClosed(){
            this.model = JSON.parse(JSON.stringify(this.backModel));
        }
    }
}
</script>