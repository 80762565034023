<template>
	<div>
		<b-modal no-close-on-backdrop :title="title" centered size="lg" ref="innerform" @shown="onInit" @hidden="onClosed">
			<div class="vl-parent" ref="ldCond">
				<div class="row mb-2">
					<div class="col-sm-4">
						<div class="form-group" :class="{ 'form-group--error': $v.form.ClientId.$error }">
							<label class="form-control-label">Client</label>
							<select class="form-control form-control-sm" v-model="$v.form.ClientId.$model" @change="getCompanies">
								<option v-for="ret in clients" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
							</select>
						</div>
					</div>
					<div class="col-sm-8">
						<div class="form-group" :class="{ 'form-group--error': $v.form.CompanyId.$error }">
							<label class="form-control-label">Company</label>
							<select class="form-control form-control-sm" :disabled="form.ClientId==''" v-model="$v.form.CompanyId.$model" @change="companyChanged">
								<option v-for="ret in companies" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
							</select>
						</div>
					</div>
				</div>
				<div class="row mb-2">
					<div class="col-sm-12">
						<div class="form-group">
							<label class="form-control-label">Bill To</label>
							<input type="text" class="form-control form-control-sm" v-model="form.BillTo"/>
						</div>
					</div>
				</div>
				<div class="row mb-3">
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.form.InvoiceDate.$error }">
							<label class="form-control-label">Invoice Date</label>
							<input type="date" class="form-control form-control-sm" v-model="$v.form.InvoiceDate.$model"/>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.form.DueDate.$error }">
							<label class="form-control-label">Due Date</label>
							<input type="date" class="form-control form-control-sm" :min="form.InvoiceDate" v-model="$v.form.DueDate.$model"/>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.form.SubAmount.$error }">
							<label class="form-control-label">Subscribe Amount</label>
							<input type="number" class="form-control form-control-sm" min="1" v-model="form.SubAmount" :disabled="isGenerate"/>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.form.SubExpired.$error }">
							<label class="form-control-label">Subscribe Expired</label>
							<input type="date" class="form-control form-control-sm" v-model="$v.form.SubExpired.$model" disabled/>
						</div>
					</div>
				</div>
				<div v-if="!isGenerate" class="row mb-3">
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.genForm.SubBranch.$error }">
							<label class="form-control-label">Branch</label>
							<input type="number" class="form-control form-control-sm" min="1" v-model="$v.genForm.SubBranch.$model"/>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.genForm.SubUser.$error }">
							<label class="form-control-label">Users</label>
							<input type="number" class="form-control form-control-sm" min="1" v-model="$v.genForm.SubUser.$model"/>
						</div>
					</div>
					<div class="col-sm-3">
						<div class="form-group" :class="{ 'form-group--error': $v.genForm.SubPos.$error }">
							<label class="form-control-label">POS</label>
							<input type="number" class="form-control form-control-sm" min="0" v-model="$v.genForm.SubPos.$model"/>
						</div>
					</div>
				</div>
				<div v-if="isGenerate" class="row">
					<div class="col-sm-12">
						<b-table class="table-bordered table-striped table-sm data-table" :fields="fields" :items="lines">
							<template #cell(No)="data">{{ data.index + 1 }}</template>
							<template #cell(Description)="data">
								<span v-html="data.item.Description"></span>
							</template>
							<template v-slot:custom-foot>
								<tr>
									<th colspan="5" class="text-end">Total: </th>
									<th class="text-end">{{$money(form.Total)}}</th>
								</tr>
								<tr>
									<th colspan="5" class="text-end">VAT(10%): </th>
									<th class="text-end">{{$money(form.Vat)}}</th>
								</tr>
								<tr>
									<th colspan="5" class="text-end">Grand Total: </th>
									<th class="text-end">{{$money(form.GrandTotal)}}</th>
								</tr>
							</template>
						</b-table>
					</div>
				</div>
			</div>
			<template slot="modal-footer">
				<b-button v-if="isGenerate" size="sm" variant="success" :disabled="form.CompanyId==''" @click="onCancel"><i class="fa fa-check-square-o" aria-hidden="true"></i> Cancel</b-button>
				<b-button v-if="!isGenerate" size="sm" variant="success" :disabled="form.CompanyId==''" @click="onGenerate"><i class="fa fa-check-square-o" aria-hidden="true"></i> Generate Invoice</b-button>
				<b-button v-if="isGenerate" variant="outline-primary" size="sm" @click="onSave"><i class="fa fa-floppy-o" aria-hidden="true"></i> {{ okModal }}</b-button>
			</template>
		</b-modal>
	</div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
    name: 'modal-payment',
    props: {
        editId: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            okModal: "Save",
            title: "NEW INVOICE",
            form: {
				InvoiceDate: this.$now().format('YYYY-MM-DD'),
				DueDate: this.$now().add(7, 'days').format('YYYY-MM-DD'),
                ClientId: "",
                CompanyId: "",
				SubAmount: 1,
				SubExpired: this.$now().format('YYYY-MM-DD'),
				GrandTotal: 0,
				Vat: 0,
				Total: 0
            },
			genForm: {
				SubBranch: 0,
				SubUser: 0,
				SubPos: 0
			},
			dafaultForm: {},
			fields: [
			{
					key: 'No',
					label: 'No',
					class: 'text-center'
				},
				{
					key: 'Description',
					label: 'Description'
				},
				{
					key: 'Quantity',
					label: 'Quantity',
					class: 'text-center'
				},
				{
					key: 'UnitPrice',
					label: 'Unit Price',
					class: 'text-end',
					formatter: value => this.$money(value)
				},
				{
					key: 'SubAmount',
					label: 'Subscribe',
					class: 'text-center'
				},
				{
					key: 'Total',
					label: 'Total',
					class: 'text-end',
					formatter: value => this.$money(value)
				}
			],
			lines: [],
			clients: [],
			companies: [],
			isGenerate: false,
        };
    },
    validations: {
        form: {
			InvoiceDate: { required },
			DueDate: { required },
			SubExpired: { required },
			ClientId: { required },
			CompanyId: { required },
			SubAmount: { required }
        },
		genForm: {
			SubBranch: { required },
			SubUser: { required },
			SubPos: { required }
		}
    },
	created(){
		this.dafaultForm = JSON.parse(JSON.stringify(this.form));
	},
    methods: {
		async onInit(){
			let loading = this.$loading.show({
				container: this.$refs.ldCond
			});
			await this.getClient();
			if(this.editId != ''){
				await this.findById();
				await this.getCompanies();
			}
			loading.hide();
		},
		async getClient(){
			let res = await this.$api().post('client-list', { IsConfirmed: true });
			if(this.$isValid(res)){
				this.clients = res.data.Json.Clients;
			}
		},
		async getCompanies(){
			let req = {
				ClientId: this.form.ClientId
			};
            let res = await this.$api().post('company-list', req);
			if(this.$isValid(res)){
				this.companies = res.data.Json.Companies
			}
        },
		companyChanged(){
			this.form.BillTo = this.companies.find(el => el.Id == this.form.CompanyId).BillTo;
		},
		async onGenerate(){
			let loading = this.$loading.show({
				container: this.$refs.ldCond
			});
			this.genForm.ClientId = this.form.ClientId;
			this.genForm.CompanyId = this.form.CompanyId;
			this.genForm.SubAmount = this.form.SubAmount;
            let res = await this.$api().post('invoice/generate', this.genForm);
			if(this.$isValid(res)){
				this.lines = res.data.Json.Lines;
				this.isGenerate = true;
				this.calculate();
			}		
			loading.hide();
		},
		async findById(){
			let res = await this.$api().post('invoice/find', { Id: this.editId});
			if(this.$isValid(res)){
				this.form = res.data.Json.Header;
				this.lines = res.data.Json.Lines;
				this.form.InvoiceDate = this.$format(this.form.InvoiceDate, 'YYYY-MM-DD');
				this.form.DueDate = this.$format(this.form.DueDate, 'YYYY-MM-DD');
				this.form.SubExpired = this.$format(this.form.SubExpired, 'YYYY-MM-DD');
				this.title = 'UPDATE INVOICE [' + this.form.InvoiceNum + ']';
				this.isGenerate = true;
			}
		},
		async onSave() {
			this.$v.$touch();
			if (!this.$v.$invalid) {
				let loading = this.$loading.show({
					container: this.$refs.ldCond
				});
				let req = {
					Header: this.form,
					Lines: this.lines
				};
				let res = await this.$api().post('invoice/save', req);
				if(this.$isValid(res)){
					this.$emit('saved', req);
				}
				loading.hide();
			}
		},
		onClosed(){
			this.lines = [];
			this.form = JSON.parse(JSON.stringify(this.dafaultForm));
			this.$v.$reset();
		},
		onCancel(){
			this.lines = [];
			this.isGenerate = false;
		},
		calculate(){
			let total = 0;
			this.lines.forEach(el => {
				total += el.Total;
			});
			this.form.Total = total;
			this.form.Vat = total * 0.1;
			let grandTotal = total + this.form.Vat;
			this.form.GrandTotal = grandTotal;
			this.form.Balance = grandTotal;
		}
	},
	watch: {
        editId(nval){
			this.title = nval==''? 'NEW INVOICE':'UPDATE INVOICE';
        },
		'form.InvoiceDate'(){
			this.form.DueDate = this.$date(this.form.InvoiceDate).add(7, 'days').format('YYYY-MM-DD');
		},
		'form.SubAmount'(nvalue){
			this.form.SubExpired = this.$date(this.form.InvoiceDate).add(nvalue, 'months').format('YYYY-MM-DD');
		}
    },
}
</script>