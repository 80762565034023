<template>
    <b-card class="card-cus">
        <b-card-header class="px-0" header-bg-variant="transparent">
            <div class="d-flex justify-content-between">
                <span class="card-title">MENUS</span>
                <div class="d-flex" style="gap: 5px;">
                    <b-button variant="primary" @click="onAdd" size="sm">
                        <i class="fa fa-plus-circle"></i> ADD NEW
                    </b-button>
                    <button type="button" class="btn btn-primary btn-sm" @click="$refs.file.click()"><i class="fa fa-upload"></i> {{ $t('import') }}</button>
                </div>
            </div>
        </b-card-header>
        <b-card-body class="px-0">
            <input type="file" ref="file" accept="application/json" style="display: none;" @change="onJsonSelect">
            <div class="d-flex justify-content-between mb-3">
                <div class="d-flex gap8">
                    <div class="form-group">
                        <label class="form-control-label">Search...</label>
                        <input type="text" class="form-control form-control-sm" v-model="query.Text" @keyup.enter="toList"/>
                    </div>
                    <div class="form-group">
                        <label class="form-control-label">Module</label>
                        <div>
                            <select class="form-control form-control-sm" v-model="query.ModuleId" @change="toList">
                                <option value="">{{$t('all')}}</option>
                                <option v-for="ret in modules" :key="ret.Id" :value="ret.Id">{{ret.Code + ': ' + ret.Name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <div>
                            <b-button variant="outline-secondary" size="sm" @click="toList"><i class="fa fa-search"></i> Search</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <b-table class="data-table" show-empty hover
                :busy="isLoading"
                :items="records"
                :fields="columns">
                <template #table-busy>
                    <div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
                </template>
                <template v-slot:cell(No)="row">{{(row.index + 1)}}</template>
                <template v-slot:cell(Enabled)="row">
                    <input type="checkbox" v-model="row.item.Enabled" disabled/>
                </template>
                <template v-slot:cell(AllowedClient)="row">
                    <input type="checkbox" v-model="row.item.AllowedClient" disabled/>
                </template>
                <template v-slot:cell(AllowedUser)="row">
                    <input type="checkbox" v-model="row.item.AllowedUser" disabled/>
                </template>
                <template v-slot:cell(Icon)="row">
                    <i :class="row.item.Icon" aria-hidden="true"></i>
                </template>
                <template v-slot:cell(Action)="row">
                    <b-button size="sm" variant="outline-info" @click="onedit(row.item)"><i class="fa fa-pencil-square"></i></b-button>
                </template>
            </b-table>
        </b-card-body>
        <b-modal no-close-on-backdrop centered title="NEW MENU" ref="form" @hidden="onFormClosed">
            <div class="vl-parent" ref="ldCond">
                <div class="row mb-3">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('module')}}</label>
                            <div>
                                <select class="form-control form-control-sm" v-model="model.ModuleId">
                                    <option v-for="ret in modules" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('type')}}</label>
                            <div>
                                <select class="form-control form-control-sm" v-model="model.TypeId" :disabled="model.ModuleId==''" @change="typeChange">
                                    <option value="1">PARENT</option>
                                    <option value="2">MENU</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">Parent Menu</label>
                            <div>
                                <select class="form-control form-control-sm" v-model="model.ParentId" :disabled="model.TypeId==1">
                                    <option value="">Not Set</option>
                                    <option v-for="ret in parentMenus" :key="ret.Id" :value="ret.Id">{{ret.Name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-12 form-group">
                        <label class="form-control-label">{{$t('name')}}</label>
                        <div>
                            <input type="text" class="form-control form-control-sm" v-model="model.Name" />
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('link')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Link" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('lang_key')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.LangKey" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-3">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label blue">{{$t('code')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Code" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('icon')}}</label>
                            <div>
                                <input type="text" class="form-control form-control-sm" v-model="model.Icon" />
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label class="form-control-label">{{$t('sort')}}</label>
                            <div>
                                <input type="number" class="form-control form-control-sm" v-model="model.Sort" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group mb-2">
                            <label><input type="checkbox" v-model="model.Enabled" /> {{$t('enabled')}}</label>
                        </div>
                        <div class="form-group mb-2">
                            <label><input type="checkbox" v-model="model.AllowedClient" /> Allow client access</label>
                        </div>
                        <div class="form-group mb-2">
                            <label><input type="checkbox" v-model="model.AllowedUser" /> Allow user access</label>
                        </div>
                    </div>
                </div>
            </div>
            <template slot="modal-footer">
                <b-button @click="onSave" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> Save</b-button>
            </template>
        </b-modal>
    </b-card>
</template>
<script>
export default {
    name: 'menu-list',
    data(){
        return{
            query: {
                Text: '',
                ModuleId: ''
            },
            columns: [
                { key: 'No'},
                { key: 'Code'},
                { key: 'Name'},
                { key: 'ModuleName', label: 'Module'},
                { key: 'Link'},
                { key: 'Icon'},
                { key: 'Sort'},
                { key: 'Enabled'},
                { key: 'AllowedClient', label: 'Client' },
                { key: 'AllowedUser', label: 'User' },
                { key: 'Action', class: 'text-end'}
            ],
            modules: [],
            records: [],
            formHead: { headers: { 'Content-Type': 'multipart/form-data' }},
            isLoading: false,
            model: {
                TypeId: 2,
                Name: '',
                Code: '',
                Icon: '',
                Sort: 0,
                ModuleId: '',
                ParentId: ''
            },
            backModel: {},
            actionLink: 'system/menu-save'
        }
    },
    created(){
        this.backModel = JSON.parse(JSON.stringify(this.model));
        this.$api().post('system/modules', {}).then(res => {
            if(this.$isValid(res)){
                this.modules = res.data.Json.Modules;
            }
        });
        this.toList();
    },
    methods: {
        toList(){
            this.isLoading = true;
            this.$api().post('system/menus', this.query).then(res => {
                if(this.$isValid(res)){
                    this.records = res.data.Json.Menus;
                }
            }).finally(() => { this.isLoading = false; });
        },
        onJsonSelect(){
            var loading = this.$loading.show();
            var formData = new FormData();
            formData.append('file', this.$refs.file.files[0]);
            this.$api().post('system/json-import-menu', formData, this.formHead).then(res => {
                if (this.$isValid(res)) {
                    this.toList();
                }
            }).finally(function () {
                loading.hide();
            });
        },
        onAdd(){
            this.$refs.form.show();
        },
        onedit(item){
            this.$refs.form.show();
            this.findById(item.Id);
        },
        onFormClosed(){
            this.model = JSON.parse(JSON.stringify(this.backModel));
        },
        findById(id){
            var loading = this.$loading.show({
                container: this.$refs.ldCond
            });
            this.$api().post('system/menu-find', { Id: id }).then(res => {
                if(this.$isValid(res)){
                    this.model = res.data.Json.Menu;
                }
            }).finally(function() { loading.hide(); });
        },
        onSave(){
            var loading = this.$loading.show({
                container: this.$refs.ldCond
            });
            this.$api().post(this.actionLink, this.model).then(res => {
                if(this.$isValid(res)){
                    this.$router.go(-1);
                }
            }).finally(function() { loading.hide(); });
        },
        typeChange(){
            if(this.model.TypeId == 2) this.model.ParentId = '';
        }
    }
}
</script>