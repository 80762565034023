<template>
    <div></div>
</template>
<script>
    import font from '../font/fonts.json'
    export default {
        name: 'invoice_pdf',
        methods: {
            print(company, data, customer) {
                var pdfMake = require("pdfmake/build/pdfmake.js");
                pdfMake.vfs = {
                    "siemreap.ttf": font.siemreap,
                    "moul.ttf": font.moul,
                    "battambang.ttf": font.battambang,
                    "battambang_bold.ttf": font.battambang_bold,
                };
                pdfMake.fonts = {
                    siemreap: {
                        normal: "siemreap.ttf",
                        bold: "siemreap.ttf",
                    },
                    moul: {
                        normal: "moul.ttf",
                        bold: "moul.ttf",
                    },
                    battambang: {
                        normal: "battambang.ttf",
                        bold: "battambang_bold.ttf",
                    },
                };
                let docDefinition = {
                    defaultStyle: {
                        font: "battambang",
                        fontSize: 10,
                    },
                    styles: {
                        fontTitle: {
                            fontSize: 12,
                            color: "#377abd",
                        },
                        table_column_header: {
                            fontSize: 12,
                            bold: true,
                        },
                    },
                    pageSize: "A4",
                    pageMargins: [20, 20, 20, 30],
                    content: [
                        ...this.header(company, data.sale_invoice_headers, customer),
                        // ...this.table(data.sale_invoice_lines),
                        // this.footerTable(data.sale_invoice_headers),
                    ],
                };
                pdfMake.createPdf(docDefinition).open();
                docDefinition = {}
            },
            header(company = {}, invoice_headers = {}, customer = {}) {
                let payment_type = '';
                return [
                    {
                        columns: [
                            {
                                width: 1,
                                columns: [
                                    {
                                        text: '',
                                        width: 50,
                                        height: 50,
                                        margin: [0, 10, 0, 0]
                                    }
                                ]
                            },
                            {
                                margin: [0, 15, 0, 0],
                                alignment: 'center',
                                type: 'none',
                                ol: [
                                    {
                                        text: company.name,
                                        font: "moul",
                                        fontSize: 12,
                                        style: "fontTitle"
                                    },
                                    {
                                        text: company.latin_name,
                                        fontSize: 12,
                                        bold: true,
                                        style: "fontTitle",
                                    },
                                    {
                                        text: [
                                            "អាសយដ្ឋាន៖",
                                            { text: this.$isEmpty(company.home) ? "" : " ផ្ទះលេខ " + company.home },
                                            { text: this.$isEmpty(company.street) ? "" : " ផ្លូវ " + company.street },
                                            { text: this.$isEmpty(company.group_no) ? "" : " ក្រុមទី " + company.group_no },
                                            { text: this.$isEmpty(company.village) ? "" : " ភូមិ " + company.village },
                                            { text: this.$isEmpty(company.commune) ? "" : " សង្កាត់ " + company.commune },
                                            { text: this.$isEmpty(company.district) ? "" : " ខណ្ឌ " + company.district },
                                            { text: this.$isEmpty(company.capital) ? "" : " រាជធានី " + company.capital }
                                        ]
                                    },
                                    {
                                        text: [{ text: this.$isEmpty(company.phone) ? "" : "Tel៖ " + company.phone }, { text: this.$isEmpty(company.email) ? "" : " Email៖ " + company.email }]
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        columns: [
                            {

                                margin: [-10, 0, 0, 0],
                                alignment: 'center',
                                type: 'none',
                                ol: [
                                    {
                                        text: "_________________________________________________________________________",
                                        margin: [0, -10, 0, 0],
                                        font: "siemreap",
                                        fontSize: 13
                                    },
                                    {
                                        margin: [0, 15, 0, 0],
                                        text: "វិក្កយបត្រ",
                                        font: "moul",
                                        fontSize: 10,
                                        style: "fontTitle",
                                    },
                                    {
                                        text: "COMERCIAL INVOICE",
                                        fontSize: 10,
                                        bold: true,
                                        style: "fontTitle",
                                    },
                                    {
                                        columns: [
                                            {
                                                width: 375,
                                                margin: [-10, 0, 0, 0],
                                                alignment: 'left',
                                                type: 'none',
                                                ol: [
                                                    {
                                                        alignment: 'left',
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(customer) == true ? "" : this.$t('customer'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(customer) == true ? "" : "   ៖   " + customer.first_name + ' ' + customer.last_name + ' ' + '(' + customer.code + ')',
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        alignment: 'left',
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(payment_type) == true ? "" : this.$t('payment_method'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(payment_type) == true ? "" : "   ៖   " + payment_type,
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        alignment: 'left',
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(customer.phone) == true ? "" : this.$t('mobile'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(customer.phone) == true ? "" : "   ៖   " + customer.phone,
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        alignment: 'left',
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(customer.address) == true ? "" : this.$t('address'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(customer.address) == true ? "" : "   ៖   " + customer.address,
                                                            }
                                                        ]
                                                    },
                                                ]
                                            },
                                            {
                                                alignment: 'left',
                                                type: 'none',
                                                ol: [
                                                    {
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(invoice_headers.code) == true ? "" : this.$t('invoice_no'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(invoice_headers.code) == true ? "" : "   ៖   " + invoice_headers.code,
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        columns: [
                                                            {

                                                                text: this.$isEmpty(invoice_headers.date) == true ? "" : this.$t('date'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(invoice_headers.date) == true ? "" : "   ៖   " + this.$toDate(invoice_headers.date),
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(invoice_headers.branch_name) == true ? "" : this.$t('branch'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(invoice_headers.branch_name) == true ? "" : "   ៖   " + invoice_headers.branch_name,
                                                            }
                                                        ]
                                                    },
                                                    {
                                                        columns: [
                                                            {
                                                                text: this.$isEmpty(invoice_headers.employee_name) == true ? "" : this.$t('sales_rep'),
                                                                width: 60
                                                            },
                                                            {
                                                                text: this.$isEmpty(invoice_headers.employee_name) == true ? "" : "   ៖   " + invoice_headers.employee_name,
                                                            }
                                                        ]
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                ]
                            }
                        ],
                    },
                ];
            },
            table(items) {
                let dataTable = [];
                let totalAmount = 0;
                for (let i = 0; i < items.length; i++) {
                    totalAmount += Number(items[i].amount);
                    let col = [
                        {
                            text: i + 1,
                            alignment: "center",
                        },
                        {
                            text: [{ text: items[i].item_info, bold: true },
                                this.$isEmpty(items[i].code) ? "" : "\n" + "- " + this.$t('code') + " ៖ " + items[i].code,
                                this.$isEmpty(items[i].item_type_name) ? "" : "\n" + "- " + this.$t('type') + " ៖ " + items[i].item_type_name,
                                this.$isEmpty(items[i].engine_no) ? "" : "\n" + "- " + this.$t('engine_no') + " ៖ " + items[i].engine_no,
                                this.$isEmpty(items[i].plate_no) ? "" : "\n" + "- " + this.$t('plate_no') + " ៖ " + items[i].plate_no,
                                this.$isEmpty(items[i].item_no) ? "" : "\n" + "- " + this.$t('item_no') + " ៖ " + items[i].item_no,
                                this.$isEmpty(items[i].machine_no) ? "" : "\n" + "- " + this.$t('machine_no') + " ៖ " + items[i].machine_no,
                                this.$isEmpty(items[i].engine_status) ? "" : items[i].engine_status == 1 ? "\n" + "- " + this.$t('status') + " ៖ " + this.$t('new') : "\n" + "- " + this.$t('status') + " ៖ " + this.$t('second_hand')
                            ],
                            alignment: "left",
                        },
                        {
                            text: items[i].qty,
                            alignment: "center",
                        },
                        {
                            text: items[i].price,
                            alignment: "right",
                        },
                        {
                            text: items[i].amount,
                            alignment: "right",
                        },
                    ];
                    dataTable.push(col);
                }
                return [
                    {
                        margin: [0, 35, 0, 0],
                        table: {
                            headerRows: 1,
                            widths: ["4%", "50%", "10%", "18%", "18%"],
                            body: [
                                [
                                    {
                                        text: this.$i18n.locale == 'kh' ? 'ល.រ' : '#',
                                        alignment: "center",
                                        bold: true,
                                    },
                                    {
                                        text: this.$t('item_info'),
                                        alignment: "left",
                                        bold: true,
                                    },
                                    {
                                        text: this.$t('quantity'),
                                        alignment: "center",
                                        bold: true,
                                    },
                                    {
                                        text: this.$t('price'),
                                        alignment: "right",
                                        bold: true,
                                    },
                                    {
                                        text: this.$t('total'),
                                        alignment: "right",
                                        bold: true,
                                    },
                                ],
                                ...dataTable,
                                [
                                    {
                                        colSpan: 4,
                                        text: this.$t('total') + " ៖",
                                        alignment: "right",
                                        bold: true,
                                        border: [true, true, false, true]
                                    },
                                    {},
                                    {},
                                    {},
                                    {
                                        text: this.$money(totalAmount),
                                        alignment: "right",
                                        bold: true,
                                        border: [false, true, true, true]
                                    }
                                ],
                            ],
                        },
                        layout: {
                            // fillColor: function (rowIndex, node, columnIndex) {
                            // return rowIndex === 0 ? '#CCCCCC' : null
                            // },
                            hLineWidth: function () {
                                return 0.5
                            },
                            vLineWidth: function () {
                                return 0.5
                            },
                        },
                    },
                ];
            },
            footerTable(invoice_headers) {
                return {
                    unbreakable: true,
                    type: 'none',
                    margin: [-10, 20, 0, 0],
                    ol: [
                        {
                            text: this.$t('note') + " ៖",
                            bold: true
                        },
                        {
                            text: this.$t('thanks') + "..."
                        },
                        { qr: invoice_headers.code, alignment: "center", fit: 70, margin: [0, -30, 0, 0] },
                        {
                            margin: [-10, 50, 0, 0],
                            columns: [
                                {
                                    type: 'none',
                                    alignment: "center",
                                    margin: [-100, 0, 0, 0],
                                    ol: [
                                        {
                                            text: "_______________",
                                            font: "siemreap",
                                            fontSize: 13
                                        },
                                        {
                                            alignment: "center",
                                            text: this.$t('signature_and_customer_name')
                                        },
                                        {
                                            alignment: "center",
                                            text: this.$isEmpty(invoice_headers.customer_name) ? "" : invoice_headers.customer_name
                                        }
                                    ]
                                },
                                {
                                    type: 'none',
                                    alignment: "center",
                                    margin: [0, 0, -100, 0],
                                    ol: [
                                        {
                                            text: "_______________",
                                            font: "siemreap",
                                            fontSize: 13
                                        },
                                        {
                                            alignment: "center",
                                            text: this.$t('signature_and_seller_name')
                                        },
                                        {
                                            alignment: "center",
                                            text: this.$isEmpty(invoice_headers.employee_name) ? "" : invoice_headers.employee_name
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            margin: [0, 20, 0, 0],
                            alignment: "left",
                            text: this.$t('note') + "៖ " + this.$t('original_document_for_buyer_and_copy_document_for_seller')
                        }
                    ]
                }
            },
        }
    };
</script>
