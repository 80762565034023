import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'
Vue.use(VueRouter);
import DefaultContainer from "../layouts/DefaultContainer";
import Dashboard from "../views/dashboard";
import ClientList  from "../views/clients";
import * as Payment  from "../views/payments";
import * as Subscribe  from "../views/subscribes";
import CompanyList  from "../views/company";
import BranchList  from "../views/branch";
import { UserList, UserGroupList }  from "../views/users";
import * as Setting from '../views/settings';
import * as Dev from '../views/developers';
import * as Loc from '../views/locations';
import Login  from "../views/login";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/", component: DefaultContainer,
      children: [
        { path: "/", component: Dashboard, meta: { title: 'Dashboard', code: '1000' } },
        { path: "/client-list", component: ClientList, meta: { title: 'Client', code: '1000' } },
        { path: "/payment-list", component: Payment.List, meta: { title: 'Billing/Subscription', code: '1000' } },
        { path: "/billing-subscription", component: Subscribe.List, meta: { title: 'Payment', code: '1000' } },
        { path: "/user-list", component: UserList, meta: { title: 'User', code: '1000' } },
        { path: "/branch-list", component: BranchList },
        { path: "/company-list", component: CompanyList, meta: { title: 'Company', code: '1000' } },      
        { path: "/user-group-list", component: UserGroupList, meta: { title: 'User Group', code: '1000' } }
      ]
    },
    {
      path: '/setting', component: DefaultContainer,
      children: [
        { path: "setup-mail-server", component: Setting.MailServer, meta: { title: 'Setup Mail Server', code: '1000' } },
        { path: "setup-price", component: Setting.SetupPrice, meta: { title: 'Setup Price', code: '1000' } },
        { path: "location", component: Loc.Capital, meta: { title: 'Location', code: '1000' } },
      ]
    },
    {
      path: '/developer', component: DefaultContainer,
      children: [
        { path: "module-list", component: Dev.ModuleList, meta: { title: 'Module', code: '1000' } },
        { path: "menu-list", component: Dev.MenuList, meta: { title: 'Menu', code: '1000' } },
        { path: "translate", component: Dev.Translate, meta: { title: 'Translate', code: '1000' } },
        { path: "configuration", component: Dev.Configuration, meta: { title: 'Configuration', code: '1000' } }
      ]
    },
    { path: "/login", name: 'login', component: Login, meta: { title: 'Login', code: '1000' } },
  ]
});
router.beforeEach((to, from, next) =>{
  if(to.name == 'login'){
    next();
  }else{
    if (!store.state.isLogin) {
      next('/login');
    }
    next();
  }
  document.title = (to.meta.title != undefined?to.meta.title.toUpperCase():'');
});
export default router;
