import Vue from "vue";
import Vuex from "vuex";
import base from "../plugins/api"
import status from './status'
Vue.use(Vuex);
export default new Vuex.Store({
	modules:{
		status
	},
	state: {
		UserName: "",
		isLogin: false,
		isChangePassword: 0
	},
	getters: {},
	mutations: {
		loginSuccess(state, user_name){
			state.UserName = user_name;
            state.isLogin = true;
        },
		updateLang(state, lang){
            state.lang = lang;
        },
		errorToken(state, action){
			state.isLogin = action;
		},
		changePassword(state, n){
			state.isChangePassword = n;
		}
	},
  actions: {
    async verifyLogin({ commit }) {
		let jwt = localStorage.getItem(base.keyStorage);
		if(jwt != undefined && jwt != null){
			try {
				let res = await base.api()({
					method: 'POST',
					url: 'account/verify',
					headers: {
						Authorization: 'Bearer ' + base.getToken()
					}
				});
				if(res.status == 200){
					commit("loginSuccess", res.data.Json.UserName);
					commit("updateLang", res.data.Json.Lang);
				}
			} catch (error) {
				localStorage.removeItem(base.keyStorage);
				commit("errorToken", false);
			}
		}
    }
  }
});
