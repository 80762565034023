import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Plugin from './plugins'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import 'bootstrap-vue/dist/bootstrap-vue.css'
//sweetalert2
import 'sweetalert2/src/sweetalert2.scss'
//crop image
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
Vue.component('vue-cropper',VueCropper);
//vue2-datepicker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
Vue.component('date-picker', DatePicker);
//vue-loading-overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading, {
  color: '#0856ab',
});
import { i18n } from './plugins/i18n';
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Plugin);
Vue.use(Vuelidate);
Vue.config.productionTip = false

store.dispatch('verifyLogin').then(() => {
  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app")
})
