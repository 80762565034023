<template>
	<div>
		<Modal @save="onSaved" :value="rowItem" :event="event" ref="form"></Modal>
		<b-card class="card-cus">
			<b-card-header class="px-0" header-bg-variant="transparent">
				<div class="d-flex justify-content-between">
					<span class="card-title">CLIENTS</span>
					<div>
						<b-button variant="primary" @click="onAdd" size="sm">
							<i class="fa fa-plus-circle"></i> ADD NEW
						</b-button>
					</div>
				</div>
			</b-card-header>
			<b-card-body class="px-0">
				<div class="d-flex justify-content-between">
					<div class="d-flex gap8">
						<div class="form-group">
							<label class="form-control-label">Search...</label>
							<input type="text" class="form-control form-control-sm" v-model="filter.Text" @keyup.enter="toList"/>
						</div>
						<div class="form-group">
							<label>&nbsp;</label>
							<div>
								<b-button variant="outline-secondary" size="sm" @click="toList"><i class="fa fa-search"></i> Search</b-button>
							</div>
						</div>
					</div>
				</div>
				<br>
				<b-table
					selectable
					select-mode="single"
					selected-variant="info"
					class="data-table"
					show-empty
					hover
					:busy="isBusy"
					:items="items"
					:fields="header"
					responsive>
					<template #table-busy>
						<div class="text-center my-2"><b-spinner variant="info" class="align-middle"></b-spinner></div>
					</template>
					<template v-slot:cell(IsConfirmed)="row">
						<span v-if="row.item.IsConfirmed" class="badge" style="background-color: #28a745 !important;">Yes</span>
						<span v-else class="badge" style="background-color: #dc3545 !important;">No</span>
					</template>
					<template v-slot:cell(action)="row">
						<b-dropdown text="Action" variant="outline-secondary" class="drop-sm" size="sm">
							<template v-if="!row.item.IsConfirmed">
								<b-dropdown-item-button @click="edit(row.item)"><i class="fa fa-pencil-square"></i> Edit</b-dropdown-item-button>
							</template>
							<b-dropdown-item-button @click="sendToEmail(row.item.Id)"><i class="fa fa-send"></i> Send To Email</b-dropdown-item-button>
						</b-dropdown>
					</template>
				</b-table>
			</b-card-body>
			<b-card-footer>
				<b-row class="mt-2">
                    <b-col sm="12" md="6" lg="7" xl="7">
                        <b-pagination
							v-model="filter.Page"
							:total-rows="filter.Total"
							:per-page="filter.Limit"
							align="right"
							pills
							@input="toList"/>
                    </b-col>
				</b-row>
			</b-card-footer>
		</b-card>
	</div>
</template>
<script>
import Modal from './components/modal.vue'
export default {
	name: 'client-list',
	components:{ Modal },
	data() {
		return {
			isBusy: false,
			header: [
				{
					key: 'action',
					label: '#',
					class: 'action-size'
				},
				{
					key: 'Name',
					label: 'Name'
				},
				{
					key: 'Email',
					label: 'Email',
				},
				{
					key: 'Phone',
					label: 'Phone'
				},
				{
					key: 'IsConfirmed',
					label: 'Confirmed',
					class: 'text-center'
				},
				{
					key: 'CreatedBy',
					label: 'Creator',
                    class: 'text-center'
				}
			],
			filter: {
				Text: '',
				Limit: 25,
				Page: 1,
				Total: 0
			},
            items: [],
			event: 0,
			rowItem: {}
		}
	},
	mounted() {
        this.toList();
    },
	methods: {
        toList(){
			this.isBusy = true
            this.$api().post('client-list', this.filter).then(res => {
                this.setInput(res)
			}).finally(() => {
				this.isBusy = false
			});
        },
        setInput(response) {
			this.items = response.data.Json.Clients;
			this.filter.Total = response.data.Total;
		},
		sendToEmail(id) {
			this.$swal.fire({
				title: "Are you sure?",
				text: "You want send email to client?",
				type: "question",
				icon: "warning",
				confirmButtonText: "Send",
				cancelButtonText: "Cancel",
				showCancelButton: true,
				reverseButtons: true,
			}).then((results) => {
				if (results.isConfirmed == true) {
                var loading = this.$loading.show();
				this.$api().post("mail-to-client", { ClientId: id })
					.then((res) => {
						if(this.$isValid(res)){
							this.$swal.fire("Message", 'Send to email success',  "success");
						}
					}).catch((err) => {
						this.$swal.fire("Warning message", err,  "warning");
					}).finally(() => {
                    loading.hide();
                });
				}
			});
		},
		edit(item){
			this.rowItem = item;
			this.event = 1;
			this.$refs.form.$refs.innermodal.show();
		},
		onAdd(){
			this.event = 0;
			this.$refs.form.$refs.innermodal.show();
		},
		onSaved(){
			this.$refs.form.$refs.innermodal.hide();
			this.$swal.fire("Message", 'Client save success.',  "success");
			this.toList();
		}
	}
}
</script>
