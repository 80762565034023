import axios from 'axios';
import Swal from 'sweetalert2';
import CryptoJS from "crypto-js";
export default {
    keyStorage: 'jwt@2693ef35',
    baseUrl: '',
    secretKey: '2693ef35-1474-40f9-93ec-2b18c8059014',
    base(){
        return 'https://adminapi.onebooks.net/api/';
        //return 'http://localhost:5100/api/';
    },
    api(){
        return axios.create({
            baseURL: this.base()
        });
    },
    request(){
        return axios.create({
            baseURL: this.base(),
            headers: { Authorization: 'Bearer ' + this.getToken() }
        });
    },
    isValid(res){
        let result = true;
        if(res.status == 401){
            Swal.fire('Warning Message', '401 Unauthorized', 'error');
            return;
        }else if(res.data.IsError){
            Swal.fire('Warning Message', res.data.Message, 'error');
            return;
        }
        return result;
    },
    setToken(token){
        let toten = CryptoJS.AES.encrypt(token, this.secretKey).toString();
        localStorage.setItem(this.keyStorage, toten);
    },
    getToken(){
        let jwt = localStorage.getItem(this.keyStorage);
        return CryptoJS.AES.decrypt(jwt, this.secretKey).toString(CryptoJS.enc.Utf8);
    }
}