<template>
    <b-modal no-close-on-backdrop :title="title" @show="initForm" ref="innermodal">
        <div class="vl-parent" ref="ldCond">
            <b-row class="mb-3">
                <div class="col-sm-6 form-group" :class="{ 'form-group--error': $v.form.Name.$error }">
                    <label class="form-control-label">Name</label>
                    <input class="form-control form-control-sm" type="text" v-model="$v.form.Name.$model" />
                </div>
                <div class="col-sm-6 form-group">
                    <label class="form-control-label">Phone</label>
                    <input class="form-control form-control-sm" type="text" v-model="form.Phone" />
                </div>
            </b-row>
            <b-row class="mb-3">
                <div class="col-sm-12 form-group" :class="{ 'form-group--error': $v.form.Email.$error }">
                    <label class="form-control-label">Email</label>
                    <input class="form-control form-control-sm" type="text" v-model="$v.form.Email.$model" />
                </div>
            </b-row>
        </div>
        <template slot="modal-footer">
            <b-button @click="save" variant="outline-primary" size="sm"><i class="fa fa-floppy-o" aria-hidden="true"></i> {{ okModal }}</b-button>
        </template>
    </b-modal>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
    name: 'modal-client',
    props: {
        value: {
            type: Object,
            default: () => {
                return {};
            },
        },
        event: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            okModal: "Save",
            title: "NEW CLIENT",
            form: {
                Name: '',
                Phone: '',
                Email: '',
                CompanyAllowed: 1,
                BranchAllowed: 2
            }
        };
    },
    validations: {
        form: {
			Name: { required },
			Email: { required },
        },
    },
    watch: {
        event(nval){
            if(nval == 0){
                this.title = 'NEW CLIENT';
                this.okModal = 'Save';
                this.form = {
                    Name: '',
                    Phone: '',
                    Email: '',
                    CompanyAllowed: 1,
                    BranchAllowed: 2
                };
            }else{
                this.title = 'UPDATE CLIENT';
                this.okModal = 'Update';
                this.form = JSON.parse(JSON.stringify(this.value));
            }
        }
    },
    methods: {
        initForm(){
            this.$v.$reset();
        },
        async save() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let loading = this.$loading.show({
                    container: this.$refs.ldCond
                });
                let res = await this.$api().post("client-save", this.form);
                if(this.$isValid(res)){
                    this.$emit("save", this.form);
                }
                loading.hide();
            }
        }
    },
};
</script>